import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import React from "react";
import ProfileTab from "./ProfileTab.tsx";
import DetailTabStyle from "../../Styles.module.scss";
interface DetailTabProps {
  candidateData: any;
  allJobExperiences: any;
}
interface DetailTabState {
  value: number;
}
interface TabProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

class TabPanel extends React.Component<TabProps> {
  constructor(props: TabProps) {
    super(props);
  }
  render() {
    const { children, value, index, ...other } = this.props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`detail-tab-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value == index && <Box>{children}</Box>}
      </div>
    );
  }
}

class DetailTabs extends React.Component<DetailTabProps, DetailTabState> {
  state: DetailTabState;
  constructor(props: DetailTabProps) {
    super(props);
    this.state = {
      value: 0,
    };
  }
  handleChange = (ev: any, newValue: number) => {
    this.setState({ value: +newValue });
  };
  render() {
    return (
      <>
        {/* <Tabs
          value={this.state.value}
          onChange={this.handleChange}
          // textColor="#093554"
          indicatorColor="#093554"
          aria-label="secondary tabs"
          className={DetailTabStyle.tabs}
        >
          <Tab
            value={0}
            label="Profile"
            id={`simple-tab-${0}`}
            aria-controls={`simple-tabpanel-${0}`}
            className={`${this.state.value == 0 && DetailTabStyle.tab}`}
          />
        </Tabs> */}
        <Box>
          <TabPanel value={this.state.value} index={0}>
            {this.props.candidateData && (
              <ProfileTab
                candidateData={this.props.candidateData}
                allJobExperiences={this.props.allJobExperiences}
              />
            )}
          </TabPanel>
        </Box>
      </>
    );
  }
}

export default DetailTabs;
