import React, { useState } from "react";
import ContactUsModal from "../modals/ContactUsModal";
import "./home.css";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";

const ContactComponent = ({ contactInfo }) => {
  const [isContactUs, setisContactUs] = useState(false);
  const [ref, inView] = useInView({ threshold: 0 });

  const titleAnimation = useSpring({
    transform: inView ? "translateY(0)" : "translateY(20px)",
    opacity: inView ? 1 : 0,
    delay: 100,
  });

  const descAnimation = useSpring({
    transform: inView ? "translateY(0)" : "translateY(20px)",
    opacity: inView ? 1 : 0,
    delay: 300,
  });

  return (
    <div className="contactSvgMain">
      <div ref={ref} className="contact-home-main-div">
        <animated.h1 style={titleAnimation} className="contact-home-header">
          contact us
        </animated.h1>
        <animated.div style={descAnimation} className="blogBorder mv-40" />
        <div>
          <animated.p style={descAnimation} className="home-contact-desc">
            {`${contactInfo?.description}`}
          </animated.p>
        </div>
        <animated.button
          style={descAnimation}
          className="home-contact-btn margin-top-40"
          onClick={() => setisContactUs(true)}
        >
          <span className="home-contact-btn-text">Contact Us</span>
        </animated.button>

        <ContactUsModal
          isContactUs={isContactUs}
          setisContactUs={setisContactUs}
        />
      </div>
      <div className="contactEllipse1"></div>
      <div className="contactEllipse2"></div>
      <div className="contactEllipse3"></div>
      <div className="contactEllipse4"></div>
    </div>
  );
};

export default ContactComponent;
