import React, { useState } from "react";
import "./team.css";
import { team1 } from "../../helper/assets";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import { linkedLogo } from "../../helper/assets";

const TeamCard = ({ data, key, onClick }) => {
  const [ref1, inView1] = useInView({ threshold: 0 });

  const animationProps1 = useSpring({
    transform: inView1 ? "translateY(0)" : "translateY(20px)",
    opacity: inView1 ? 1 : 0,
  });
  return (
    <animated.div
      ref={ref1}
      style={animationProps1}
      key={key}
      onClick={onClick}
      className="team-card-div"
    >
      <div className="leader-img-div">
        {data?.image && (
          <img src={data?.image} alt="leader-img" className="leader-img" />
        )}
      </div>
      <div className="leader-info-div">
        {data?.name && <span className="leader-name-text">{data?.name}</span>}
        {data?.team_role && (
          <span className="leader-status">{data?.team_role}</span>
        )}
      </div>
    </animated.div>
  );
};

export default TeamCard;
