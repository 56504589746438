import React, { useEffect, useState } from "react";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer, toast, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HomePage from "./pages/home-pages/HomePage";
import About from "./pages/about-page/About";
import Candidates from "./pages/candidates-page/Candidates";
import BlogsPage from "./pages/blogs-page/BlogsPage";
import BlogViewMore from "./components/blogs-compenents/BlogViewMore";
import Team from "./pages/team-page/Team";
import GdprPolicy from "./pages/gdprpolicy-page/GdprPolicy";
import TermsandCondition from "./pages/tandc-page/TermsandCondition";
import PrivacyPolicy from "./pages/privacy-page/PrivacyPolicy";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/candidate",
    element: <Candidates />,
  },
  {
    path: "/blogs",
    element: <BlogsPage />,
  },
  {
    path: "/blogdetails",
    element: <BlogViewMore />,
  },
  {
    path: "/team",
    element: <Team />,
  },
  {
    path: "/gdprpolicy",
    element: <GdprPolicy />,
  },
  {
    path: "/termsandcondition",
    element: <TermsandCondition />,
  },
  {
    path: "/privacypolicy",
    element: <PrivacyPolicy />,
  },
]);

function App() {
  const [cookieAccepted, setCookieAccepted] = useState(true);

  useEffect(() => {
    const cookies = document.cookie.split("; ");
    for (let i = 0; i < cookies.length; i++) {
      const [cookieName, cookieValue] = cookies[i].split("=");
      if (cookieName === "myCookie") {
        setCookieAccepted(false);
      }
    }
  }, [cookieAccepted]);
  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        transition={Slide}
        toastClassName={"custom-toast"}
        limit={1}
      />
      {cookieAccepted && (
        <div id="popup" class="popup-container">
          <div
            style={{
              position: "absolute",
              bottom: 0,
              padding: "20px 30px",
              background: "#24325f",
              left: 0,
              right: 0,
              zIndex: 999,
            }}
          >
            <div className="cookies-div">
              <div className="cookies-first-div">
                <h5 className="cookies-privacy-text">We Value Your Privacy</h5>
                <p className="cookies-desc-text">
                  This website uses cookies to make sure you get the best
                  experience on our website. You can find more information under
                  the{" "}
                  <a
                    href={"/privacypolicy"}
                    style={{
                      textDecoration: "underline",
                      color: "#fff",
                    }}
                  >
                    Privacy Statement
                  </a>{" "}
                  and{" "}
                  <a
                    href={"/gdprpolicy"}
                    style={{ textDecoration: "underline", color: "#fff" }}
                  >
                    Cookie Policy
                  </a>
                </p>
              </div>

              <button
                className="accept-cookie-btn"
                onClick={() => {
                  document.cookie = "myCookie=true";
                  setCookieAccepted(false);
                }}
              >
                Accept All cookies
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
