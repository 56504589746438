import React, { Component } from "react";
import { CircularProgress } from "@material-ui/core";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Grid } from "@material-ui/core";
import { ChangeEvent } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputBase,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
  Input,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import CandidateCard from "./CandidateCard.tsx";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import PersonIcon from "@material-ui/icons/Person";
//@ts-ignore
import CatalogueStyles from "../../Styles.module.scss";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ExpandMoreOutlined, ErrorRounded } from "@material-ui/icons";
import { title } from "process";
import axios from "axios";
import Carousel from "react-elastic-carousel";
import {
  JobIcon,
  SearchIcon,
  LocationIcon,
  TextIcon,
  CompanyIcon,
  ProfileIcon,
  upload,
  location,
  skills,
  keywords,
  jobRole,
  cross,
} from "../../helper/assets.js";
import { toast } from "react-toastify";

const breakPoints = [
  { width: 600, itemsToShow: 1 },
  { width: 900, itemsToShow: 2 },
  { width: 1000, itemsToShow: 3 },
];

const dummyKms = [
  { value: 15, label: "15km" },
  { value: 40, label: "40km" },
  { value: 55, label: "55km" },
  { value: 80, label: "80km" },
  { value: 120, label: "120km" },
  { value: 160, label: "160km" },
];

const dummyStatus = ['Show "Watched"', 'Exclude "Watched"'];
const dummyCP = ["Current and Past", "Current", "Past"];

export interface TPState {
  location: string;
  title: string;
  keyword_OR_name: string;
  fullName: string;
  km: {
    value: number;
    label: string;
  };
  status: string | 'Show "Watched"' | 'Exclude "Watched"';
  cp: string | "Current and Past" | "Current" | "Past";
  title_boolean_search: boolean;
  title_boolean_location: boolean;
  keyword: boolean;
  minExperience: string;
  maxExperience: string;
  years_of_exp_from: number | "";
  years_of_exp_to: number | "";
  full_name: boolean;
  company: string;
  showText: string;
  showTextLoc: string;
  showkeyword_OR_nameText: string;
  showLocation: any;
  showTitle: any;
  showBooleanTitle: any;
  showBooleanLoc: any;
  showNonBooleanTitle: any;
  showCompany: any;
  showYearErorr: boolean;
  temp: any;
  orOption: boolean;
  orTitle: boolean;
  orCompany: boolean;
  showPopup: boolean;
  searchdata: any;
  Result: MatchingResult[];
  job_description: string;
  keywords: string[];
  jdLocation: string;
  ShowList: boolean;
  showlistData: any;
  jobDescriptionText: string;
  windowWidth: number;
  maxExperienceError: boolean;
  minExperienceError: boolean;
  isFileuploadLoader: boolean;
  skills: any;
  maximum_years_of_experience: any;
  minimum_years_of_experience: any;
  skillsError: boolean;
  locationError: boolean;
  selectedOption: string;
  fileName: any;
  role: any;
}

const CustomRadio = withStyles({
  root: {
    color: "rgba(9, 53, 84, 0.6)",
    "&$checked": {
      color: "rgba(9, 53, 84, 1)",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const notifySuccess = (success) => {
  toast.dismiss();
  toast.success(success, {
    position: "top-center",
    autoClose: 3000,
  });
};

const notifyError = (error) => {
  toast.dismiss();
  toast.error(error, {
    position: "top-center",
    autoClose: 3000,
  });
};

interface Props {
  // classes: any;
  onSearch: (state: TPState) => void;
  onAdvanceSearch: (state: TPState) => void;
  showSaveSearch?: boolean;
  locationList?: any[];
  companyList?: any[];
  titleList?: any[];
  keywordsList?: any[];
  onSuggessionSearch: (type: string, searchText: string) => void;
  onReset: () => void;
  classes?: any;
}

interface MatchingResult {
  Xcelyst_AI_Score: number;
  id: string;
  fullname: string;
  certification: string | null;
  course: string | null;
  education: string | null;
  experience: string | null;
  experienceyears: number | null;
  headline: string | null;
  linkedin: string | null;
  locations: string | null;
  project: string | null;
  skills: string | null;
  summary: string | null;
}

class Filter extends Component<Props, TPState> {
  fileInputRef = React.createRef<HTMLInputElement>();

  initialState: TPState = {
    orOption: true,
    orTitle: true,
    orCompany: true,
    location: "",
    title: "",
    keyword_OR_name: "",
    fullName: "",
    km: dummyKms[0],
    status: "",
    cp: dummyCP[0],
    title_boolean_search: false,
    title_boolean_location: true,
    keyword: true,
    full_name: false,
    company: "",
    showText: "",
    showTextLoc: "",
    years_of_exp_from: "",
    years_of_exp_to: "",
    minExperience: "",
    maxExperience: "",
    maxExperienceError: false,
    minExperienceError: false,
    showkeyword_OR_nameText: "",
    showLocation: [],
    showTitle: [],
    showBooleanTitle: [],
    showBooleanLoc: [],
    showNonBooleanTitle: [],
    showCompany: [],
    showYearErorr: false,
    temp: "",
    showPopup: false,
    searchdata: [],
    Result: [],
    job_description: "",
    keywords: [],
    jdLocation: "",
    ShowList: false,
    jobDescriptionText: "",
    windowWidth: window.innerWidth,
    showlistData: [],
    isFileuploadLoader: false,
    skills: [],
    maximum_years_of_experience: "",
    minimum_years_of_experience: "",
    skillsError: false,
    locationError: false,
    selectedOption: "keyword",
    fileName: "",
    role: [],
  };

  state: TPState = { ...this.initialState };

  togglePopup = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };

  toggleListPopup = () => {
    this.setState({ ShowList: !this.state.ShowList });
  };

  handleJobDescriptionText = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ jobDescriptionText: event.target.value });
  };

  handleMinExperienceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      minExperience: event.target.value,
      minExperienceError: false,
    });
  };

  handleMaxExperienceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      maxExperience: event.target.value,
      maxExperienceError: false,
    });
  };

  handleKeywords = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const keywordsArray = input.split(",").map((keyword) => keyword);
    this.setState({ skills: keywordsArray?.slice(0, 3) });
  };

  handleRoles = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const roleArray = input.split(",").map((role) => role);
    this.setState({ role: roleArray?.slice(0, 3) });
  };

  handleSkillsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ skills: event.target.value });
  };

  handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ location: event.target.value }, () => {
      if (this.state.location === "") {
        this.setState({ locationError: true });
      } else {
        this.setState({ locationError: false });
      }
    });
  };

  onSearch = () => {
    // var str = this.state.keyword_OR_name.replaceAll('"AND"', '').replaceAll("'AND'", '')
    //     .replaceAll("'", "").replaceAll('"', ''

    this.props.onSearch(this.state);
  };

  onAdvanceSearch = () => {
    this.props.onAdvanceSearch(this.state);
  };

  advanceSearchValidation = () => {
    if (this.state.job_description === "") {
      notifyError("please upload your JD");
    } else if (this.state.minExperience === "") {
      this.setState({ minExperienceError: true });
    } else if (this.state.location === "") {
      this.setState({ locationError: true });
    } else {
      setTimeout(() => {
        this.onAdvanceSearch();
        this.setState({ showPopup: false });
      }, 1000);
    }
  };

  onReset = () => {
    this.setState({ ...this.initialState });
    this.props.onReset();
  };

  onUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files || files.length === 0) {
      return;
    }

    const file = files[0];
    const formData = new FormData();
    formData.append("file", file);
    // formData.append("metadata", JSON.stringify({ skills: this.state.skills }));

    this.setState({ isFileuploadLoader: true, fileName: file?.name });
    try {
      const response = await axios.post(
        "https://advanced.xcelyst.ai/jd_parsing",
        formData
      );

      if (response.status === 200) {
        this.setState({ isFileuploadLoader: false });
        const {
          job_description,
          location,
          maxExperience,
          minExperience,
          roles,
          skills,
          bow,
        } = response.data;

        this.setState({
          job_description,
          keywords: bow,
          location: location ? location : "",
          maxExperience: maxExperience ? maxExperience : "",
          minExperience: minExperience ? minExperience : "",
          isFileuploadLoader: false,
          role: roles,
          skills: skills,
        });
      } else {
        notifyError("Failed to upload JD file");
        this.setState({ isFileuploadLoader: false });
      }
    } catch (error) {
      notifyError(error.message || "facing issues");
      console.log(">>>>>>>>>>error", error);
      this.setState({ isFileuploadLoader: false });
    }
  };

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState((prev) => ({
      title_boolean_location: !prev.title_boolean_location,
    }));
    event.target.checked
      ? this.setState({ location: this.state.showBooleanLoc })
      : this.setState({
          location: this.state.showNonBooleanTitle,
        });
  };

  handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState((prev) => ({
      title_boolean_search: !prev.title_boolean_search,
    }));
    event.target.checked
      ? this.setState({ title: this.state.showBooleanTitle })
      : this.setState({
          title: this.state.showNonBooleanTitle,
        });
  };

  handleOptionChange = (event) => {
    this.setState({ selectedOption: event.target.value });
  };

  render() {
    let timeoutVar: any;
    const { classes } = this.props;

    return (
      <Box className={`${CatalogueStyles.card} ${CatalogueStyles.filterWrap}`}>
        <Box className={CatalogueStyles.filterCard}>
          <Box className={CatalogueStyles.filterCardBox}>
            <Typography
              variant="h6"
              gutterBottom
              className={CatalogueStyles.filterTitle}
            >
              Location
            </Typography>
            {this.state.title_boolean_location ? (
              <Box className="location-box">
                <Box className={CatalogueStyles.filterIcon}>
                  <LocationIcon />
                </Box>
                <Box className={CatalogueStyles.searchBiBox}>
                  <Input
                    className={CatalogueStyles.showSeachText}
                    placeholder="location"
                    onChange={(event) => {
                      let receivedText: any = event.target.value;
                      let boolArray = ["OR", "or"];
                      let forT = receivedText
                        ?.trim()
                        ?.split(" ")
                        ?.map((item: any) => {
                          if (boolArray.includes(item)) {
                            return `<span style='color: #24325f;font-weight:600'>${item}</span>`;
                          } else return item;
                        });
                      this.setState({
                        showTextLoc: forT?.join(" ") || "",
                        showBooleanLoc: receivedText,
                        location: receivedText,
                      });
                    }}
                    onKeyPress={(event) =>
                      event.key == "Enter" && event.preventDefault()
                    }
                    disableUnderline
                    value={this.state.showBooleanLoc}
                  />
                </Box>
              </Box>
            ) : (
              <Box className="location-box">
                <Box className={CatalogueStyles.filterIcon}>
                  <SearchIcon />
                </Box>
                <Autocomplete
                  className={CatalogueStyles.search2}
                  classes={{ root: CatalogueStyles.inputRoot }}
                  multiple
                  disableClearable
                  noOptionsText="No Data Found"
                  value={this.state.showLocation}
                  loading={this.props.locationList === null}
                  options={
                    this.props.locationList?.map((option: any) => option) || []
                  }
                  onFocus={() => {
                    this.setState({ orOption: true });
                    this.props.onSuggessionSearch("location", "");
                  }}
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timeoutVar);
                    timeoutVar = setTimeout(() => {
                      this.props.onSuggessionSearch("location", newInputValue);
                    }, 700);
                  }}
                  onChange={(event, value) => {
                    event.stopPropagation();
                    event.preventDefault();
                    this.setState({
                      location: value?.join(" OR "),
                      showLocation: [...value],
                    });
                  }}
                  renderTags={(value, getTagProps) => {
                    if (this.state.orOption) {
                      return value?.map((option: string, index: number) => (
                        <>
                          <Typography
                            variant="body1"
                            className={CatalogueStyles.searchTextTags}
                          >
                            {option}
                          </Typography>
                          {index < value.length && (
                            <span className={CatalogueStyles.boolKeys}>
                              {" "}
                              OR{" "}
                            </span>
                          )}
                        </>
                      ));
                    } else {
                      return value?.map((option: string, index: number) => (
                        <>
                          <Typography
                            variant="body1"
                            className={CatalogueStyles.searchTextTags}
                          >
                            {option}
                          </Typography>
                          {index < value.length - 1 && (
                            <span className={CatalogueStyles.boolKeys}>
                              {" "}
                              OR{" "}
                            </span>
                          )}
                        </>
                      ));
                    }
                  }}
                  onBlur={() => {
                    this.setState({ orOption: false });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      multiline
                      maxRows={3}
                      minRows={1}
                      placeholder="location"
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (event) => {
                          if (event.key === "Enter") {
                            event.stopPropagation();
                            event.preventDefault();
                          }
                        },
                      }}
                    />
                  )}
                />
              </Box>
            )}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 10,
                gap: 8,
              }}
            >
              <label className="custom-checkbox">
                <input
                  type="checkbox"
                  checked={this.state.title_boolean_location}
                  onChange={this.handleChange}
                  disabled={false} // Set to true to see the disabled effect
                />
                <span className="checkmark"></span>
              </label>
              <span className="location-label-text">
                Location boolean search
              </span>
            </div>
          </Box>
          <Box flex={1} className={CatalogueStyles.filterCardBox}>
            <Typography
              variant="h6"
              gutterBottom
              className={CatalogueStyles.filterTitle}
            >
              Title
            </Typography>
            {this.state.title_boolean_search ? (
              <Box className="location-box">
                <Box className={CatalogueStyles.filterIcon}>
                  <JobIcon />
                </Box>
                <Box className={CatalogueStyles.searchBiBox}>
                  <Input
                    className={CatalogueStyles.showSeachText}
                    multiline
                    placeholder="Enter title"
                    onChange={(event) => {
                      let receivedText: any = event.target.value;
                      let boolArray = ["AND", "OR", "NOT", "and", "or", "not"];
                      let forT = receivedText
                        ?.trim()
                        ?.split(" ")
                        ?.map((item: any) => {
                          if (boolArray.includes(item)) {
                            return `<span style='color: #24325f;font-weight:400'>${item}</span>`;
                          } else return item;
                        });
                      this.setState({
                        showText: forT?.join(" ") || "",
                        showBooleanTitle: receivedText,
                        title: receivedText,
                      });
                    }}
                    onKeyPress={(event) =>
                      event.key == "Enter" && event.preventDefault()
                    }
                    disableUnderline
                    value={this.state.showBooleanTitle}
                  />
                </Box>
              </Box>
            ) : (
              <Box className="location-box">
                <Box className={CatalogueStyles.filterIcon}>
                  <JobIcon />
                </Box>
                <Autocomplete
                  className={CatalogueStyles.search2}
                  classes={{ root: CatalogueStyles.inputRoot }}
                  multiple
                  disableClearable
                  noOptionsText="No Data Found"
                  onFocus={() => {
                    this.setState({ orTitle: true });
                    this.props.onSuggessionSearch("title", "");
                  }}
                  loading={this.props.titleList === null}
                  options={
                    this.props.titleList?.map((option: any) => option) || []
                  }
                  onInputChange={(event, newInputValue) => {
                    clearTimeout(timeoutVar);
                    timeoutVar = setTimeout(() => {
                      this.props.onSuggessionSearch("title", newInputValue);
                    }, 700);
                  }}
                  onChange={(event, value) => {
                    event.stopPropagation();
                    event.preventDefault();
                    this.setState({
                      title: value?.join(" OR "),
                      showTitle: [...value],
                      showNonBooleanTitle: value?.join(" OR "),
                    });
                  }}
                  renderTags={(value, getTagProps) => {
                    if (this.state.orTitle) {
                      return value?.map((option: string, index: number) => (
                        <>
                          <Typography
                            variant="body1"
                            className={CatalogueStyles.searchTextTags}
                          >
                            {option}
                          </Typography>
                          {index < value.length && (
                            <span className={CatalogueStyles.boolKeys}>
                              {" "}
                              OR{" "}
                            </span>
                          )}
                        </>
                      ));
                    } else {
                      return value?.map((option: string, index: number) => (
                        <>
                          <Typography
                            variant="body1"
                            className={CatalogueStyles.searchTextTags}
                          >
                            {option}
                          </Typography>
                          {index < value.length - 1 && (
                            <span className={CatalogueStyles.boolKeys}>
                              {" "}
                              OR{" "}
                            </span>
                          )}
                        </>
                      ));
                    }
                  }}
                  onBlur={() => {
                    this.setState({ orTitle: false });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      multiline
                      maxRows={3}
                      minRows={1}
                      placeholder="Enter title"
                      inputProps={{
                        ...params.inputProps,
                        onKeyDown: (event) => {
                          if (event.key === "Enter") {
                            event.stopPropagation();
                            event.preventDefault();
                          }
                        },
                      }}
                    />
                  )}
                  value={this.state.showTitle}
                />
              </Box>
            )}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 10,
                gap: 8,
              }}
            >
              <label className="custom-checkbox">
                <input
                  type="checkbox"
                  checked={this.state.title_boolean_search}
                  onChange={this.handleTitleChange}
                  disabled={false}
                />
                <span className="checkmark"></span>
              </label>
              <span className="location-label-text">Title boolean search</span>
              <Divider
                orientation="vertical"
                style={{
                  height: "22px",
                  width: "1px",
                  backgroundColor: "rgba(135, 135, 135, 1)",
                  marginRight: "10px",
                }}
              />
              <FormControl>
                <Select
                  disabled={
                    this.state.title_boolean_search
                      ? this.state.showBooleanTitle.length === 0
                        ? true
                        : false
                      : this.state.showNonBooleanTitle.length === 0
                      ? true
                      : false
                  }
                  IconComponent={ExpandMoreOutlined}
                  className={CatalogueStyles.filterDropDown}
                  value={this.state.cp}
                  onChange={(e) =>
                    this.setState({ cp: e.target.value as string })
                  }
                >
                  {dummyCP.map((cp, index) => (
                    <MenuItem key={index} value={cp}>
                      {cp}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Box>
          <Box className={CatalogueStyles.filterCardBox}>
            <Box display="flex">
              <RadioGroup
                value={this.state.selectedOption}
                onChange={this.handleOptionChange}
                row
              >
                <FormControlLabel
                  className={CatalogueStyles.filterSubTitle}
                  value="keyword"
                  control={<CustomRadio />}
                  label="Keyword"
                />
                <FormControlLabel
                  className={CatalogueStyles.filterSubTitle}
                  value="full_name"
                  control={<CustomRadio />}
                  label="Full Name"
                />
              </RadioGroup>
            </Box>
            {this.state.selectedOption === "keyword" ? (
              <Box className="location-box">
                <Box className={CatalogueStyles.filterIcon}>
                  <TextIcon />
                </Box>
                <Box className={CatalogueStyles.searchBiBox}>
                  <Input
                    className={CatalogueStyles.showSeachText}
                    multiline
                    maxRows={3}
                    minRows={1}
                    placeholder="Enter Keyword"
                    disableUnderline
                    onChange={(event) => {
                      let receivedText = event.target.value;
                      let boolArray = ["AND", "OR", "NOT", "and", "or", "not"];
                      let forT = receivedText
                        ?.trim()
                        ?.split(" ")
                        ?.map((item) => {
                          if (boolArray.includes(item)) {
                            return `<span style='color: #24325f;font-weight:600'>${item}</span>`;
                          } else {
                            return item;
                          }
                        });
                      this.setState({
                        showkeyword_OR_nameText: forT?.join(" ") || "",
                        keyword_OR_name: receivedText,
                      });
                    }}
                    onKeyPress={(event) =>
                      event.key === "Enter" && event.preventDefault()
                    }
                    value={this.state.keyword_OR_name}
                  />
                </Box>
              </Box>
            ) : (
              <Box className="location-box">
                <Box className={CatalogueStyles.filterIcon}>
                  <TextIcon />
                </Box>
                <Box className={CatalogueStyles.nameField}>
                  <InputBase
                    placeholder="Enter Name"
                    className={CatalogueStyles.showSeachText}
                    inputProps={{ "aria-label": "search" }}
                    value={this.state.fullName}
                    onChange={(e) =>
                      this.setState({ fullName: e.target.value })
                    }
                  />
                </Box>
              </Box>
            )}
          </Box>
          <Box className={CatalogueStyles.filterCardBox}>
            <Typography
              variant="h6"
              gutterBottom
              className={CatalogueStyles.filterTitle}
            >
              Manage Profiles
            </Typography>
            <Box className="location-box">
              <Box className={CatalogueStyles.filterIcon}>
                <ProfileIcon />
              </Box>
              <FormControl>
                <Select
                  className={CatalogueStyles.filterDropDown}
                  IconComponent={ExpandMoreOutlined}
                  displayEmpty
                  renderValue={
                    this.state.status !== null
                      ? undefined
                      : () => "Choose Status"
                  }
                  value={
                    this.state.status !== null
                      ? this.state.status
                      : dummyStatus[0]
                  }
                  onChange={(e) =>
                    this.setState({ status: e.target.value as string })
                  }
                >
                  <MenuItem value={""}>Choose Status</MenuItem>
                  {dummyStatus.map((status, index) => (
                    <MenuItem key={index} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box className={CatalogueStyles.filterCardBox}>
            <Typography
              variant="h6"
              gutterBottom
              className={CatalogueStyles.filterTitle}
            >
              Company
            </Typography>
            <Box className="location-box">
              <Box className={CatalogueStyles.filterIcon}>
                <CompanyIcon />
              </Box>
              <Autocomplete
                className={CatalogueStyles.search}
                classes={{ root: CatalogueStyles.inputRoot }}
                multiple
                disableClearable
                noOptionsText="No Data Found"
                loading={this.props.companyList === null}
                onFocus={() => {
                  this.setState({ orCompany: true });
                  this.props.onSuggessionSearch("company", "");
                }}
                options={
                  this.props.companyList?.map((option: any) => option) || []
                }
                onInputChange={(event, newInputValue) => {
                  clearTimeout(timeoutVar);
                  timeoutVar = setTimeout(() => {
                    this.props.onSuggessionSearch("company", newInputValue);
                  }, 700);
                }}
                onChange={(event, value) => {
                  event.stopPropagation();
                  event.preventDefault();
                  this.setState({
                    company: value?.join(" OR "),
                    showCompany: [...value],
                  });
                }}
                renderTags={(value, getTagProps) => {
                  if (this.state.orCompany) {
                    return value?.map((option: string, index: number) => (
                      <>
                        <Typography
                          variant="body1"
                          className={CatalogueStyles.searchTextTags}
                        >
                          {option}
                        </Typography>
                        {index < value.length && (
                          <span className={CatalogueStyles.boolKeys}> OR </span>
                        )}
                      </>
                    ));
                  } else {
                    return value?.map((option: string, index: number) => (
                      <>
                        <Typography
                          variant="body1"
                          className={CatalogueStyles.searchTextTags}
                        >
                          {option}
                        </Typography>
                        {index < value.length - 1 && (
                          <span className={CatalogueStyles.boolKeys}> OR </span>
                        )}
                      </>
                    ));
                  }
                }}
                onBlur={() => {
                  this.setState({ orCompany: false });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    multiline
                    maxRows={3}
                    minRows={1}
                    placeholder="Enter company name"
                    inputProps={{
                      ...params.inputProps,
                      onKeyDown: (event) => {
                        if (event.key === "Enter") {
                          event.stopPropagation();
                          event.preventDefault();
                        }
                      },
                    }}
                  />
                )}
                value={this.state.showCompany}
              />
            </Box>
          </Box>
          <Box className={CatalogueStyles.filterCardBox}>
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              flexWrap="wrap"
            >
              <Typography
                variant="h6"
                gutterBottom
                className={CatalogueStyles.filterTitle}
                style={{ marginRight: 5 }}
              >
                Years of Experience
              </Typography>
            </Box>
            <Box
              display="flex"
              width="100%"
              justifyContent="center"
              alignItems="center"
              gridGap={20}
            >
              <TextField
                value={this.state.years_of_exp_from}
                onChange={(e) => {
                  if (!e.target.value.trim().length) {
                    this.setState({ years_of_exp_from: "" });
                  } else {
                    this.setState({
                      years_of_exp_from:
                        Number(e.target.value) > 98
                          ? 0
                          : Number(e.target.value),
                    });
                  }
                }}
                type="number"
                placeholder="From"
                fullWidth
                className={CatalogueStyles.yearsExp}
                onKeyUp={() => {
                  let start: any = this.state.years_of_exp_from.toString();
                  start.includes(".")
                    ? this.setState({
                        years_of_exp_from: parseInt(start.split(".")),
                      })
                    : parseInt(start);
                }}
              />
              <TextField
                value={this.state.years_of_exp_to}
                onChange={(e) => {
                  if (!e.target.value.trim().length) {
                    this.setState({ years_of_exp_to: "" });
                  } else {
                    this.setState({
                      years_of_exp_to:
                        Number(e.target.value) > 99
                          ? 99
                          : Number(e.target.value),
                    });
                  }
                }}
                type="number"
                placeholder="To"
                fullWidth
                className={CatalogueStyles.yearsExp}
                onKeyUp={() => {
                  let end: any = this.state.years_of_exp_to.toString();
                  end.includes(".")
                    ? this.setState({
                        years_of_exp_to: parseInt(end.split(".")),
                      })
                    : parseInt(end);
                }}
              />
            </Box>
          </Box>
          {this.state.showYearErorr && (
            <Typography
              gutterBottom
              className={CatalogueStyles.filterTitle}
              style={{ color: "red", opacity: 0.6, margin: 10 }}
            >
              <ErrorRounded style={{ fontSize: 16, verticalAlign: "sub" }} />{" "}
              Minimum year should be less than Maximum year
            </Typography>
          )}
        </Box>
        <Box
          className={CatalogueStyles.filterReset}
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={this.onReset}
        >
          <Typography>Reset</Typography>
        </Box>

        <Box className={CatalogueStyles.filterSearch} onClick={this.onSearch}>
          <Typography>Search</Typography>
        </Box>
        <Box
          className={CatalogueStyles.filterAdvanceSearch}
          onClick={() => this.togglePopup()}
        >
          <Typography>Advanced Search</Typography>
        </Box>

        <Dialog
          open={this.state.showPopup}
          onClose={this.togglePopup}
          fullWidth={true}
        >
          <div className="contactUsMain padding-20">
            <div className="contactTitleDiv">
              <div></div>
              <p className="contactTitle">Advanced Search</p>
              <img
                src={cross}
                className="crossContactImg"
                onClick={() => this.setState({ showPopup: false })}
              />
            </div>

            <div className={CatalogueStyles.uploadJdDiv}>
              <p className={CatalogueStyles.aSearchHeading}>
                Upload Job Description
              </p>
              <div className={CatalogueStyles.fileinputContainer}>
                <button className={CatalogueStyles.fileinputbutton}>
                  Choose File{" "}
                  <img src={upload} className={CatalogueStyles.uploadImg} />
                </button>
                <input
                  type="file"
                  className={CatalogueStyles.fileinput}
                  onChange={this.onUpload}
                  accept=".txt,.docx,.pdf"
                />
                {this.state.fileName && (
                  <span className={CatalogueStyles.fileName}>
                    {this.state.fileName}
                  </span>
                )}
              </div>
            </div>

            <div className="contactFormDiv1">
              {/* <div className="ContactFormFieldDiv">
                <p className="contactusFormTitle">
                  Skills{" "}
                  <span style={{ fontSize: 12, color: "red" }}>
                    (Note: *please enter skills first then select JD)
                  </span>
                </p>
                <div
                  className={`contactusInput ${
                    this.state.skillsError ? "contactusDuoTxInputError" : ""
                  }`}
                >
                  <img src={skills} className="contactImgForm" />
                  <input
                    className="contactusTxInput"
                    placeholder="Enter Skills"
                    value={this.state.skills}
                    onChange={(e) =>
                      this.setState({
                        skills: e.target.value,
                        skillsError: false,
                      })
                    }
                  />
                </div>
              </div> */}
              <div className="ContactFormFieldDiv">
                <p className="contactusFormTitle">Job Description</p>
                <div className="contactusInput">
                  <img src={jobRole} className="contactImgForm" />
                  <input
                    className="contactusTxInput"
                    placeholder="Enter Job Description"
                    disabled
                    value={this.state.job_description}
                  />
                </div>
                {/* {emailError ? <p className="errorTxt">{emailError}</p> : null} */}
              </div>
              <div className={CatalogueStyles.mt20}>
                <div className="firstNameDiv">
                  <p className="contactusFormTitle">Minimum Experience</p>
                  <div
                    className={`contactusInput ${
                      this.state.minExperienceError
                        ? "contactusDuoTxInputError"
                        : ""
                    }`}
                  >
                    <input
                      className={`contactusDuoTxInput`}
                      placeholder="Enter Minimum Experience"
                      value={this.state.minExperience}
                      onChange={this.handleMinExperienceChange}
                    />
                  </div>
                </div>

                <div className="secondNameDiv">
                  <p className="contactusFormTitle">Maximum Experience</p>
                  <div className="contactusInput">
                    <input
                      className="contactusDuoTxInput"
                      placeholder="Enter Maximum Experience"
                      value={this.state.maxExperience}
                      onChange={this.handleMaxExperienceChange}
                    />
                  </div>
                </div>
              </div>

              <div className="ContactFormFieldDiv">
                <p className="contactusFormTitle">Titles</p>
                <div className="contactusInput">
                  <img src={jobRole} className="contactImgForm" />
                  <input
                    className="contactusTxInput"
                    placeholder="Enter Titles"
                    value={this.state.role}
                    onChange={this.handleRoles}
                  />
                </div>
              </div>

              <div className="ContactFormFieldDiv">
                <p className="contactusFormTitle">Skills</p>
                <div className="contactusInput">
                  <img src={keywords} className="contactImgForm" />
                  <input
                    className="contactusTxInput"
                    placeholder="Enter Skills"
                    value={this.state.skills}
                    onChange={this.handleKeywords}
                  />
                </div>
              </div>
              <div className="ContactFormFieldDiv">
                <p className="contactusFormTitle">Location</p>
                <div
                  className={`contactusInput ${
                    this.state.locationError && "contactusDuoTxInputError"
                  }`}
                >
                  <img src={location} className="contactImgForm" />
                  <input
                    className="contactusTxInput"
                    placeholder="Enter Location"
                    value={this.state.location}
                    onChange={this.handleLocationChange}
                  />
                </div>
              </div>

              <div className={CatalogueStyles.aSearchBtnsDiv}>
                <Button
                  variant="outlined"
                  // color="primary"
                  onClick={this.onReset}
                  className={CatalogueStyles.resetModalDiv}
                >
                  Reset
                </Button>

                <Button
                  // disabled={this.state.fileName === ""}
                  onClick={this.advanceSearchValidation}
                  variant="contained"
                  disableElevation
                  // color="primary"
                  className={CatalogueStyles.searchModalDiv}
                >
                  Search
                </Button>
              </div>
            </div>
            {this.state.isFileuploadLoader && (
              <div className={"loadermaindivModal"}>
                <span className={"loaderModal"} />
              </div>
            )}
          </div>
        </Dialog>

        <Dialog
          open={this.state.ShowList}
          onClose={this.toggleListPopup}
          fullWidth={true}
          maxWidth="md"
        >
          <DialogTitle>Candidate list</DialogTitle>

          <div>
            {this.state.showlistData.length > 0 ? (
              <div>
                {this.state.showlistData.map((item: any) => {
                  return (
                    <div>
                      <p>fullname: {item.fullname}</p>
                      <p>headline: {item.headline}</p>
                      <p>Xcelyst_AI_Score: {item.score}</p>
                      <p>linkedin: {item.linkedin}</p>
                      <p>education: {item.education}</p>
                      <p>experience: {item.experience}</p>
                      <p>locations: {item.locations}</p>
                      <p>skills: {item.skills}</p>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p>Not Found Data</p>
            )}
          </div>
        </Dialog>
      </Box>
    );
  }
}

export default Filter;
