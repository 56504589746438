import React from "react";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import "./home.css";

const CardService = ({ data }) => {
  const [ref, inView] = useInView({ threshold: 0 });
  const animationProps = useSpring({
    transform: inView ? "translateY(0)" : "translateY(20px)",
    opacity: inView ? 1 : 0,
  });

  return (
    <animated.div ref={ref} style={animationProps} className="service-card-div">
      <div className="sevice-card-header">
        {data?.image && (
          <img className="service-icon" src={data?.image} alt={"icon"} />
        )}
        {data?.title && (
          <span className="service-card-header-text">{data?.title}</span>
        )}
      </div>
      {data?.description && (
        <p className="service-card-desc mt-26">{data?.description}</p>
      )}
    </animated.div>
  );
};

export default CardService;
