export const headerLogo = require("../assets/icons/LogoHeader.svg");
export const footerLogo = require("../assets/icons/LogoFooter.svg");

export const caraousel1 = require("../assets/images/Banner1.webp");
export const caraousel2 = require("../assets/images/Banner2.webp");
export const caraousel3 = require("../assets/images/Banner3.webp");
export const caraousel4 = require("../assets/images/Banner4.webp");
export const caraousel5 = require("../assets/images/Banner5.webp");

//blog
export const backArrow = require("../assets/icons/backarrow.png");
export const like = require("../assets/icons/Star.png");
export const comment = require("../assets/icons/message-2.png");
export const linkedIn = require("../assets/icons/linkedin.png");
export const twitter = require("../assets/icons/twitter.png");
export const filledLike = require("../assets/icons/filledStar.png");
//contact us
export const user = require("../assets/icons/UserForm.png");
export const cross = require("../assets/icons/cross.png");
export const global = require("../assets/icons/global.png");
export const chat = require("../assets/icons/message-text.png");
export const linkdin = require("../assets/icons/linkdin.png");
export const sms = require("../assets/icons/sms.png");
export const call = require("../assets/icons/call.png");
export const about1 = require("../assets/images/aboutUs.webp");
export const about2 = require("../assets/images/aboutUs2.webp");
export const aboutusHome = require("../assets/images/aboutUsHome.webp");
export const leader1 = require("../assets/images/Leader1.webp");
export const leader2 = require("../assets/images/Leader2.webp");
export const leader3 = require("../assets/images/Leader3.webp");
export const partner1 = require("../assets/images/Partner1.webp");
export const partner2 = require("../assets/images/Partner2.webp");
export const partner3 = require("../assets/images/Partner3.webp");
export const partner4 = require("../assets/images/Partner4.webp");

//footer
export const twitterFooter = require("../assets/icons/twitterFooter.png");

//services icon
export const target = require("../assets/icons/target.png");
export const dollar = require("../assets/icons/dollar.png");
export const cloud = require("../assets/icons/cloud.png");
export const chartLine = require("../assets/icons/chart-line.png");
export const alarm = require("../assets/icons/alarm.png");
export const linkedLogo = require("../assets/icons/linkedin-logo.png");
export const twitterLogo = require("../assets/icons/twitter-logo.png");
export const facebookLogo = require("../assets/icons/facebook.png");

export { ReactComponent as JobIcon } from "../assets/icons/job.svg";
export { ReactComponent as SearchIcon } from "../assets/icons/search.svg";
export { ReactComponent as LocationIcon } from "../assets/icons/location.svg";
export { ReactComponent as TextIcon } from "../assets/icons/text.svg";
export { ReactComponent as CompanyIcon } from "../assets/icons/company.svg";
export { ReactComponent as ProfileIcon } from "../assets/icons/profile.svg";

export const skills = require("../assets/icons/Skills.png");
export const keywords = require("../assets/icons/Keywords.png");
export const jobRole = require("../assets/icons/job.png");
export const location = require("../assets/icons/location.png");
export const upload = require("../assets/icons/Upload.png");

export const team1 = require("../assets/images/team1.png");
