import React, { useEffect, useState, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import "./team.css";
import NavbarComponent from "../../components/navbar/NavbarComponent";
import Footer from "../../components/footer/Footer";
import {
  BASE_URL,
  teamLeaderData,
  teamPartnerData,
  TEAM_INFO_END_POINT,
} from "../../helper/config";
import TeamLeaderCard from "../../components/team/TeamLeaderCard";
import PartnerCard from "../../components/team/PartnerCard";
import axios from "axios";
import Loader from "../../components/loader/Loader";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import {
  cross,
  linkedLogo,
  facebookLogo,
  twitterLogo,
} from "../../helper/assets";
import { toast } from "react-toastify";
import TeamCard from "../../components/team/TeamCard";

const Team = () => {
  const location = useLocation();
  const [teamData, setTeamData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [leaderInfo, setLeaderInfo] = useState({});
  const [leaderInfoModal, setLeaderInfoModal] = useState(false);

  const notifyError = (error) => {
    toast.dismiss();
    toast.error(error, {
      position: "top-center",
      autoClose: 3000,
    });
  };

  const teamDataFunction = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}${TEAM_INFO_END_POINT}`);
      if (response.status === 200) {
        setTeamData(response.data?.data);
        setLoading(false);
      } else {
        setLoading(false);
        notifyError(response.message || "facing issues");
      }
    } catch (error) {
      setLoading(false);
      notifyError(error.message || "facing issues");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    teamDataFunction();
  }, []);

  const [ref1, inView1] = useInView({ threshold: 0 });
  const [ref2, inView2] = useInView({ threshold: 0 });
  const [ref3, inView3] = useInView({ threshold: 0 });

  const animationProps1 = useSpring({
    transform: inView1 ? "translateY(0)" : "translateY(20px)",
    opacity: inView1 ? 1 : 0,
  });

  const animationProps2 = useSpring({
    transform: inView2 ? "translateY(0)" : "translateY(20px)",
    opacity: inView2 ? 1 : 0,
  });

  const animationProps3 = useSpring({
    transform: inView3 ? "translateY(0)" : "translateY(20px)",
    opacity: inView3 ? 1 : 0,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div className="main-container">
      <NavbarComponent />
      <div style={{ flex: 1 }}>
        <animated.div
          ref={ref1}
          style={animationProps1}
          className="about-section-1"
        >
          <h1 className="about-text">
            Trusted Partners for Your Long-Term Success
          </h1>
          <div className="blogBorder mv-24" />
          <p className="team-tagline-text">
            Experience the power of our leadership team, supporting partners,
            and advisory board.
          </p>
        </animated.div>
        <div>
          {teamData?.team_description && (
            <animated.div
              ref={ref2}
              style={animationProps2}
              className="team-section-1"
            >
              {teamData?.team_description?.map((item) => {
                return (
                  <div className="experince-team-div" key={item.id}>
                    <h2 className="experience-label">{item.title}</h2>
                    <p
                      className="experience-desc"
                      dangerouslySetInnerHTML={{ __html: item?.description }}
                    />
                  </div>
                );
              })}
            </animated.div>
          )}
        </div>
        {teamData?.management_team && (
          <animated.div
            ref={ref3}
            style={animationProps3}
            className="team-section-2"
          >
            <h2 className="team-meet-text">Meet your team</h2>
            <p className="team-meet-text-tagline">
              Meet your team of experienced professionals who provide hands-on
              operational knowledge to support your success.
            </p>
            <div className="team-info-section">
              {teamData?.management_team?.length > 0 && (
                <p className="leadership-text">Management Team</p>
              )}
              {teamData?.management_team?.length > 0 && (
                <div className="partner-card-div">
                  {teamData?.management_team?.map((item) => {
                    return (
                      <TeamCard
                        data={item}
                        key={item.id}
                        onClick={() => {
                          setLeaderInfoModal(true);
                          setLeaderInfo(item);
                        }}
                      />
                    );
                  })}
                </div>
              )}
              {teamData?.advisory_board?.length > 0 && (
                <p className="leadership-text">Advisory Board</p>
              )}
              {teamData?.advisory_board?.length > 0 && (
                <div className="partner-card-div">
                  {teamData?.advisory_board?.map((item) => {
                    return (
                      <TeamCard
                        data={item}
                        key={item.id}
                        onClick={() => {
                          setLeaderInfoModal(true);
                          setLeaderInfo(item);
                        }}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          </animated.div>
        )}
      </div>
      <Footer />
      {loading && <Loader />}
      <Modal
        show={leaderInfoModal}
        onHide={() => setLeaderInfoModal(false)}
        dialogClassName="leader-Modal"
        contentClassName="card-data-modal"
        centered
      >
        <div className="leader-info-modal">
          <div className="leader-info-first-div">
            <div className="leader-modal-wrap">
              <div className="modal-leader-img-div">
                {leaderInfo?.image && (
                  <img
                    src={leaderInfo?.image}
                    alt="leader-img"
                    className="modal-leader-img"
                  />
                )}
              </div>
              <div className="modal-leader-name">
                {leaderInfo?.name && (
                  <span className="leader-name">{leaderInfo?.name}</span>
                )}
                {leaderInfo?.team_role && (
                  <span className="modal-leader-status">
                    {leaderInfo?.team_role}
                  </span>
                )}
              </div>
            </div>
            <img
              src={cross}
              className="crossContactImg"
              onClick={() => setLeaderInfoModal(false)}
            />
          </div>
          {leaderInfo?.bio && (
            <p className="leader-bio">{`${leaderInfo?.bio}`}</p>
          )}
          <div className="url-div">
            {leaderInfo?.url && (
              <a
                href={`${leaderInfo?.url ? leaderInfo?.url : "#"}`}
                target="_blank"
                rel="noopener noreferrer"
                className="team-linkdin-btn"
              >
                <img src={linkedLogo} className="linkedLogo" />
                <span>Linked In</span>
              </a>
            )}
            {leaderInfo?.x_url && (
              <a
                href={`${leaderInfo?.x_url ? leaderInfo?.x_url : "#"}`}
                target="_blank"
                rel="noopener noreferrer"
                className="team-linkdin-btn"
              >
                <img src={twitterLogo} className="linkedLogo" />
                <span>Twitter</span>
              </a>
            )}
            {leaderInfo?.fb_url && (
              <a
                href={`${leaderInfo?.fb_url ? leaderInfo?.fb_url : "#"}`}
                target="_blank"
                rel="noopener noreferrer"
                className="team-linkdin-btn"
              >
                <img src={facebookLogo} className="linkedLogo" />
                <span>Facebook</span>
              </a>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Team;
