// ContactModal.js

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { call, chat, cross, sms, user } from "../../helper/assets";
import "../navbar/navbar.css";
import ButtonLoader from "../loader/ButtonLoader";
import { BASE_URL, CONTACT_US_END_POINT } from "../../helper/config";
import axios from "axios";
import { toast } from "react-toastify";

const ContactUsModal = ({ isContactUs, setisContactUs }) => {
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [emailId, setemailId] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [message, setmessage] = useState("");
  const [firstNameError, setfirstNameError] = useState("");
  const [lastNameError, setlastNameError] = useState("");
  const [emailError, setemailError] = useState("");
  const [phoneError, setphoneError] = useState("");
  const [messageError, setmessageError] = useState("");
  const [loading, setLoading] = useState(false);
  const validateEmail = (email) => {
    const emailRegex = /^[a-z0-9.]+@[a-z0-9-]+\.[a-z]{2,}$/;
    return emailRegex.test(email.toLowerCase());
  };

  const notifySuccess = (success) => {
    toast.dismiss();
    toast.success(success, {
      position: "top-center",
      autoClose: 3000,
    });
  };

  const notifyError = (error) => {
    toast.dismiss();
    toast.error(error, {
      position: "top-center",
      autoClose: 3000,
    });
  };

  const contactFunction = async () => {
    setLoading(true);
    try {
      const formData = new FormData();

      formData.append("name", firstName.trim());
      formData.append("last_name", lastName.trim());
      formData.append("email", emailId.trim());
      formData.append("phone", phoneNumber);
      formData.append("message", message.trim());

      const response = await axios.post(
        `${BASE_URL}${CONTACT_US_END_POINT}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        setLoading(false);
        setisContactUs(false);
        setemailId("");
        setlastName("");
        setfirstName("");
        setmessage("");
        setphoneNumber("");
        notifySuccess("We have successfully received your request.");
      } else {
        setLoading(false);
        notifyError("An error occurred. Please try again later.");
      }
    } catch (error) {
      if (error?.response?.data) {
        notifyError(
          error?.response?.data?.message ||
            error?.response?.data?.messages?.name[0] ||
            error?.response?.data?.messages?.last_name[0] ||
            error?.response?.data?.messages?.email[0] ||
            error?.response?.data?.messages?.phone[0] ||
            error?.response?.data?.messages?.message[0]
        );
        setLoading(false);
      } else {
        notifyError(error.message);
        setLoading(false);
      }
    }
  };

  const submitHandler = () => {
    let hasErrors = false;

    setfirstNameError("");
    setlastNameError("");
    setemailError("");
    setphoneError("");
    setmessageError("");

    if (firstName.trim() === "") {
      hasErrors = true;
      setfirstNameError("First Name can't be empty.");
    }

    if (lastName.trim() === "") {
      hasErrors = true;
      setlastNameError("Last Name can't be empty.");
    }

    if (emailId === "") {
      hasErrors = true;
      setemailError("Email can't be empty.");
    } else if (!validateEmail(emailId.trim())) {
      hasErrors = true;
      setemailError("Please Enter Valid Email ID.");
    }

    if (phoneNumber.trim() === "") {
      hasErrors = true;
      setphoneError("Phone Number can't be empty.");
    }

    if (message.trim() === "") {
      hasErrors = true;
      setmessageError("Message field can't be empty.");
    }

    if (!hasErrors) {
      contactFunction();
    }
  };

  const closeModalHandler = () => {
    setisContactUs(false);
    setfirstName("");
    setlastName("");
    setphoneNumber("");
    setmessage("");
    setemailId("");
    setemailError("");
    setphoneError("");
    setfirstNameError("");
    setlastNameError("");
    setmessageError("");
  };

  return (
    <Modal
      show={isContactUs}
      onHide={() => setisContactUs(false)}
      dialogClassName="contactUsModal"
      contentClassName="modalBoxContact"
    >
      <div className="contactUsMain">
        <div className="contactTitleDiv">
          <div></div>
          <p className="contactTitle">Contact US</p>
          <img
            src={cross}
            className="crossContactImg"
            onClick={closeModalHandler}
          />
        </div>

        <div className="contactSubTitleDiv">
          <p className="contactSubText">
            If you are an employer please fill out this form and we will get
            back to you as soon as possible
          </p>
        </div>

        <div className="contactFormDiv">
          <div className="firstInnerDiv">
            <div className="firstNameDiv">
              <p className="contactusFormTitle">First Name</p>
              <div className="contactusInput">
                <img src={user} className="contactImgDuo" />
                <input
                  className="contactusDuoTxInput"
                  placeholder="Enter First Name"
                  value={firstName}
                  onChange={(e) => {
                    setfirstName(e.target.value);
                    setfirstNameError("");
                  }}
                />
              </div>
              <div className="errorDiv">
                {firstNameError ? (
                  <p className="errorTxt">{firstNameError}</p>
                ) : (
                  <p className="errorTxt"></p>
                )}
              </div>
            </div>

            <div className="secondNameDiv">
              <p className="contactusFormTitle">Last Name</p>
              <div className="contactusInput">
                <img src={user} className="contactImgDuo" />
                <input
                  className="contactusDuoTxInput"
                  placeholder="Enter Last Name"
                  value={lastName}
                  onChange={(e) => {
                    setlastName(e.target.value);
                    setlastNameError("");
                  }}
                />
              </div>
              <div className="errorDiv">
                {lastNameError ? (
                  <p className="errorTxt">{lastNameError}</p>
                ) : (
                  <p className="errorTxt"></p>
                )}
              </div>
            </div>
          </div>

          <div className="ContactFormFieldDiv">
            <p className="contactusFormTitle">Email Id</p>
            <div className="contactusInput">
              <img src={sms} className="contactImgForm" />
              <input
                className="contactusTxInput"
                placeholder="Enter Email Id"
                value={emailId}
                onChange={(e) => {
                  setemailId(e.target.value);
                  setemailError("");
                }}
              />
            </div>
            {emailError ? <p className="errorTxt">{emailError}</p> : null}
          </div>

          <div className="ContactFormFieldDiv">
            <p className="contactusFormTitle">Phone Number</p>
            <div className="contactusInput countryPickerInput">
              <PhoneInput
                country={"us"}
                value={phoneNumber}
                onChange={(text) => {
                  setphoneNumber(text);
                  setphoneError("");
                }}
                enableSearch
                inputStyle={{
                  backgroundColor: "#F8F6F6",
                  border: "none",
                  width: "100%",
                  outline: "none",
                }}
                containerStyle={{
                  outline: "none",
                  border: "none",
                }}
                searchStyle={{
                  outline: "none",
                  border: "none",
                }}
                dropdownStyle={{
                  outline: "none",
                  border: "none",
                }}
                buttonStyle={{
                  border: "none",
                  outline: "none",
                }}
              />
            </div>
            {phoneError ? <p className="errorTxt">{phoneError}</p> : null}
          </div>

          <div className="ContactFormFieldDiv">
            <p className="contactusFormTitle">Message</p>
            <div className="contactusInput">
              <img src={chat} className="contactImgForm" />
              <input
                className="contactusTxInput"
                placeholder="Enter your Message"
                value={message}
                onChange={(e) => {
                  setmessage(e.target.value);
                  setmessageError("");
                }}
              />
            </div>
            {messageError ? <p className="errorTxt">{messageError}</p> : null}
          </div>

          <div className="mainDiv">
            <button className="submitBtnDiv" onClick={submitHandler}>
              {!loading ? (
                <span className="submitTxt">Submit</span>
              ) : (
                <ButtonLoader />
              )}
            </button>
          </div>

          <div className="infoDiv">
            <p className="infoText1">
              If you are a candidate searching for job, please share your CV at{" "}
            </p>
            <a href={`mailto:info@xcelyst.com`} className="infoText2">
              info@xcelyst.com
            </a>
            {/* <p className="infoText3">OR</p>
            <div className="applyMain">
              <button className="applyBtnDiv">
                <span className="applyText">Apply for Open Position</span>
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ContactUsModal;
