import React, { useEffect, useCallback, useState } from "react";
import NavbarComponent from "../../components/navbar/NavbarComponent";
import BlogDetails from "../../components/blogs-compenents/BlogDetails";
import NewsLetter from "../../components/newsletter/NewsLetter";
import Footer from "../../components/footer/Footer";
import { useSpring, animated } from "react-spring";
import axios from "axios";
import { BASE_URL, HOME_BLOG_LISTING_END_POINT } from "../../helper/config";
import Loader from "../../components/loader/Loader";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const BlogsPage = () => {
  const location = useLocation();
  const [ref1, inView1] = useInView({ threshold: 0 });
  const [ref2, inView2] = useInView({ threshold: 0 });

  const animationProps1 = useSpring({
    transform: inView1 ? "translateY(0)" : "translateY(20px)",
    opacity: inView1 ? 1 : 0,
  });

  const animationProps2 = useSpring({
    transform: inView2 ? "translateY(0)" : "translateY(20px)",
    opacity: inView2 ? 1 : 0,
  });

  const notifyError = (error) =>{
    toast.dismiss();
    toast.error(error, {
      position: "top-center",
      autoClose: 3000,
    });
  }

  const [loading, setLoading] = useState(false);
  const [blogData, setBlogData] = useState([]);

  const homeBlogDataFunction = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}${HOME_BLOG_LISTING_END_POINT}`
      );
      if (response.status === 200) {
        setBlogData(response.data?.data);
        setLoading(false);
      } else {
        setLoading(false);
        notifyError(response.message || "facing issues")
      }
    } catch (error) {
      setLoading(false);
      notifyError(error.message || "facing issues")
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    homeBlogDataFunction();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div className="main-container">
      <NavbarComponent />
      <div style={{ flex: 1 }}>
        <div className="main-home-section">
          <animated.div
            ref={ref1}
            style={animationProps1}
            className="blogPageTitleDiv"
          >
            <p className="blogPageTitle">Blog Insights</p>

            <div className="blogBorder"></div>
            <p className="blogPageDesc">
              Stay updated with the latest trends, tips, and insights in
              recruitment and career development. Our expert articles provide
              valuable information to help you navigate the evolving job market
              and enhance your hiring strategies.
            </p>
          </animated.div>

          {blogData && (
            <animated.div
              ref={ref2}
              style={animationProps2}
              className={"blogs-main-div"}
            >
              {blogData?.map((item) => {
                return <BlogDetails item={item} />;
              })}
            </animated.div>
          )}

          <NewsLetter />
        </div>
      </div>
      <Footer />
      {loading && <Loader />}
    </div>
  );
};

export default BlogsPage;
