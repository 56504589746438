import React from "react";
import "./blog.css";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import { useNavigate } from "react-router-dom";

function BlogHome({ data }) {
  const navigate = useNavigate();
  const [ref, inView] = useInView({ threshold: 0 });
  const animationProps = useSpring({
    transform: inView ? "translateY(0)" : "translateY(20px)",
    opacity: inView ? 1 : 0,
  });

  function formatTimestampToDate(timestamp) {
    const date = new Date(timestamp);

    const day = date.getUTCDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getUTCFullYear();

    return `${day} ${month}, ${year}`;
  }

  return (
    <animated.div
      key={data?.id}
      ref={ref}
      style={animationProps}
      className="blog-home-div"
    >
      <h2 className="blog-home-title">{data?.title}</h2>
      <div className="descLearnDiv">
        <p className="blog-home-desc">
          {data?.description?.length > 300
            ? data?.description.slice(0, 300).concat("...")
            : data?.description}
        </p>
        <div className="blog-home-learn-div">
          <button
            onClick={() => navigate(`/blogdetails?id=${data?.id}`)}
            className="blog-home-learn"
          >
            Learn More
          </button>
        </div>
      </div>
      <div className="blog-home-footer">
        <span className="blog-auther">
          {formatTimestampToDate(data?.created_at)}
        </span>
        {data?.user?.name && (
          <span className="blog-auther">{data?.user?.name}</span>
        )}
      </div>
    </animated.div>
  );
}

export default BlogHome;
