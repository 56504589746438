import React, { useState, useEffect } from "react";
import "./home.css";
// import { carouselData } from "../../helper/config";
import Slider from "react-slick";

const Carousel = ({ carouselData }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isAnimating, setIsAnimating] = useState(true);
  const [zoomIndex, setZoomIndex] = useState(0);

  const animate = (animate) => {
    setIsAnimating(animate);
  };

  const settings = (animate) => ({
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    fade: false,
    cssEase: "ease-in-out",
    focusOnSelect: false,
    customPaging: (i) => <div className="custom-dot"></div>,
    appendDots: (dots) => (
      <div
        style={{
          display: windowWidth < 480 ? "none" : "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          boxSizing: "content-box",
          marginBottom: windowWidth < 768 ? "60px" : "40px",
          zIndex: 3,
        }}
      >
        {dots}
      </div>
    ),
    beforeChange: () => animate(false),
    afterChange: (current) => {
      setZoomIndex(current);
      setTimeout(() => animate(true), 50); // Slight delay to allow slide change
    },
  });

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, [windowWidth]);
  return (
    <div className="carousel-wrapper">
      <Slider {...settings(animate)}>
        {carouselData.map((item, index) => (
          <div className="carousel-div" key={index}>
            <img
              src={item?.image}
              className={`carousel-img ${
                index === zoomIndex && isAnimating ? "zoom" : ""
              }`}
              alt={`Slide ${index}`}
            />
            <p
              className={`carousel-item-text ${isAnimating ? "animate" : ""}`}
              dangerouslySetInnerHTML={{ __html: item?.title }}
            />

            <div className="overlay-carousel" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Carousel;
