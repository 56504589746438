import React, { useState, useCallback, useEffect } from "react";
import "./footer.css";
import { NavLink, useNavigate } from "react-router-dom";
import {
  linkdin,
  twitter,
  sms,
  call,
  twitterFooter,
} from "../../helper/assets";
import ContactUsModal from "../modals/ContactUsModal";
import { BASE_URL, ADDRESS_END_POINT } from "../../helper/config";
import axios from "axios";

function Footer() {
  const [isContactUs, setisContactUs] = useState(false);
  const [addressData, setAddressData] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const addressDataFunction = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}${ADDRESS_END_POINT}`);
      if (response.status === 200) {
        setAddressData(response.data?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    addressDataFunction();
  }, []);

  return (
    <div className="footer-main-div">
      <div className="footer-main">
        <div className="footer-nav-div">
          <div className="logoDiv">
            <div className="logo-div">
              <div className="logoFooter" />
              <div className="logo-header-div">
                <span className="logo-footer-text">XCELYST</span>
                <span className="logo-footer-tagline-text">
                  RECRUITMENT REINVENTED
                </span>
              </div>
            </div>
          </div>
          <div className="noLogoDiv">
            <div className="footer-nav">
              <NavLink className={`nav-link-footer `} to="/">
                Home
              </NavLink>
              <NavLink className={`nav-link-footer`} to="/about">
                About Us
              </NavLink>
              <NavLink className={`nav-link-footer`} to="/candidate">
                Candidates
              </NavLink>
              <NavLink className={`nav-link-footer `} to="/blogs">
                Blogs
              </NavLink>
              <NavLink className={`nav-link-footer`} to="/team">
                Team
              </NavLink>
              <NavLink
                className={`nav-link-footer `}
                // to="/contact"
                onClick={() => setisContactUs(true)}
              >
                Contact Us
              </NavLink>
            </div>
            <div className="footer-social-btn">
              <a
                href="https://www.linkedin.com/company/xcelyst-ltd/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={linkdin} alt="LinkedIn" className="linkdin-icon" />
              </a>
              <a
                href="https://x.com/XcelystL?t=3qVWnRupBZDp6p7kqAFo6g&s=09"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitterFooter} className="linkdin-icon" />
              </a>
            </div>
          </div>
        </div>
        {addressData && (
          <div className="footer-main-address">
            {addressData?.map((item) => {
              return (
                <div className="footer-address-div">
                  {item?.country && (
                    <p className="footer-country-name">{item?.country}</p>
                  )}
                  {item?.address && (
                    <p className="footer-address-text">{item?.address}</p>
                  )}
                  <div className="contMailDiv">
                    {item?.email && (
                      <a
                        href={`mailto:${item?.email}`}
                        className="footer-email-div"
                      >
                        <img src={sms} className="sms-icon" />
                        <span className="footer-email-text">{item?.email}</span>
                      </a>
                    )}
                    {item?.phone && (
                      <a
                        href={`tel:${item?.phone}`}
                        className="footer-phone-div"
                      >
                        <img src={call} className="sms-icon" />
                        <span className="footer-email-text">{item?.phone}</span>
                      </a>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        <div className="footer-rights-div">
          <div className="copy-right-text">
            © 2024 Xcelyst. All rights reserved.
          </div>
          <div className="footer-link-div">
            <NavLink
              className={"footer-link"}
              onClick={() => navigate("/gdprpolicy")}
            >
              GDPR Policy
            </NavLink>
            <NavLink
              className={"footer-link"}
              onClick={() => navigate("/termsandcondition")}
            >
              Terms of Services{" "}
            </NavLink>
            <NavLink
              className={"footer-link"}
              onClick={() => navigate("/privacypolicy")}
            >
              Privacy Policy
            </NavLink>
          </div>
        </div>
      </div>

      <ContactUsModal
        isContactUs={isContactUs}
        setisContactUs={setisContactUs}
      />
    </div>
  );
}

export default Footer;
