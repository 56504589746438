import React, { useState } from "react";
import "./newsLetter.css";
import { SUBSCRIBE_API_END_POINT, BASE_URL } from "../../helper/config";
import axios from "axios";
import { toast } from "react-toastify";
import ButtonLoader from "../loader/ButtonLoader";

function NewsLetter() {
  const [email, setemail] = useState("");
  const [loading, setLoading] = useState(false);

  const notifySuccess = (success) =>{
    toast.dismiss();
    toast.success(success, {
      position: "top-center",
      autoClose: 3000,
    });}

    const notifyError = (error) =>{
      toast.dismiss();
      toast.error(error, {
        position: "top-center",
        autoClose: 3000,
      });
    }

  const validateEmail = (email) => {
    const emailRegex = /^[a-z0-9.]+@[a-z0-9-]+\.[a-z]{2,}$/;
    return emailRegex.test(email.toLowerCase());
  };

  const subscribeFunction = async () => {
    setLoading(true);
    try {
      const formData = new FormData();

      formData.append("email", email.trim());

      const response = await axios.post(
        `${BASE_URL}${SUBSCRIBE_API_END_POINT}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.success) {
        setLoading(false);
        notifySuccess("Thank you for subscribing!");
        setemail("");
      } else {
        setLoading(false);
        notifyError("An error occurred. Please try again later.");
      }
    } catch (error) {
      if (error?.response?.data) {
        notifyError(
          error?.response?.data?.message ||
            error?.response?.data?.messages?.email[0]
        );
        setLoading(false);
      } else {
        notifyError(error.message);
        setLoading(false);
      }
    }
  };

  const subscribeHandler = () => {
    let hasErrors = false;

    if (email === "") {
      hasErrors = true;
      notifyError("Email can't be empty.");
    } else if (!validateEmail(email.trim())) {
      hasErrors = true;
      notifyError("Please Enter Valid Email ID.");
    }

    if (!hasErrors) {
      subscribeFunction();
    }
  };

  return (
    <div className="newsLetterMain">
      <p className="blogPageTitle newsTitle">
        Discover more from Xcelyst Partners
      </p>
      <div className="blogBorder"></div>
      <div className="blogPageDesc newsDesc">
        Subscribe to get the latest posts sent to your email.
      </div>

      <div className="textInputDiv">
        <div className="news-input-div">
          <input
            placeholder="Type your email"
            value={email}
            onChange={(e) => setemail(e.target.value)}
            className="newsInput"
          />
        </div>
        <button className="subscribeBtnDiv" onClick={subscribeHandler}>
          <p className="subsTxt"> {loading ? <ButtonLoader /> : "Subscribe"}</p>
        </button>
      </div>
    </div>
  );
}

export default NewsLetter;
