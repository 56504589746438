import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import { Modal } from "react-bootstrap";
import { cross } from "../../helper/assets";

const HeroSection = ({ data }) => {
  const [ref, inView] = useInView({ threshold: 0 });
  const [aboutInfo, setAboutInfo] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const animationProps = useSpring({
    transform: inView ? "translateY(0)" : "translateY(20px)",
    opacity: inView ? 1 : 0,
  });

  return (
    <animated.div ref={ref} style={animationProps} className="hero-div">
      <div>
        <h1 className="hero-header-text">{data?.title}</h1>
        <p
          dangerouslySetInnerHTML={{
            __html: data?.description,
          }}
          className="hero-desc"
        />
      </div>
      {data?.description?.length > 300 && (
        <div className="hero-know-more-btn">
          <button
            className="home-contact-btn"
            onClick={() => {
              setModalVisible(true);
              setAboutInfo(data);
            }}
          >
            <span className="home-contact-btn-text">Know More</span>
          </button>
        </div>
      )}
      <Modal
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        dialogClassName="leader-Modal"
        contentClassName="about-data-modal"
        centered
      >
        <div className="about-info-modal">
          <div className="about-modal-first-div">
            <div />
            {aboutInfo?.title && (
              <h3 className="hero-header-tex ">{aboutInfo?.title}</h3>
            )}
            <img
              src={cross}
              className="crossContactImg"
              onClick={() => setModalVisible(false)}
            />
          </div>
          {aboutInfo?.description && (
            <p
              dangerouslySetInnerHTML={{
                __html: aboutInfo?.description,
              }}
              className="hero-desc-modal"
            />
          )}
        </div>
      </Modal>
    </animated.div>
  );
};

export default HeroSection;
