import React, { useEffect } from "react";
import "./candidate.css";
import NavbarComponent from "../../components/navbar/NavbarComponent";
import Footer from "../../components/footer/Footer";
import CandidateDatabase from "../../components/candidate/CandidateDatabase.tsx";

const Candidates = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="main-container">
      <NavbarComponent />
      <div style={{ flex: 1, marginBottom: 20 }}>
        <div className="candiadte-main-div">
          <CandidateDatabase />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Candidates;
