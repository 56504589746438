import React, { useEffect, useCallback, useMemo, useState } from "react";
import "./homePage.css";
import NavbarComponent from "../../components/navbar/NavbarComponent";
import Carousel from "../../components/home-components/Carousel";
import BlogHome from "../../components/blogs-compenents/BlogHome";
import VisionComponents from "../../components/home-components/VisionComponents";
import ContactComponent from "../../components/home-components/ContactComponent";
import CardService from "../../components/home-components/CardService";
import AboutComponent from "../../components/home-components/About";
import Footer from "../../components/footer/Footer";
import {
  BASE_URL,
  CAROUSEL_END_POINT,
  HOME_DESCRIPTION_END_POINT,
  HOME_SINGLE_API_END_POINT,
  HOME_CARD_LISTING_END_POINT,
  HOME_BLOG_LISTING_END_POINT,
  KNOW_MORE_END_POINT,
} from "../../helper/config";
import HeroSection from "../../components/home-components/HeroSection";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import axios from "axios";
import Loader from "../../components/loader/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const HomePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [ref, inView] = useInView({ threshold: 0 });
  const [loading, setLoading] = useState(false);
  const [carouselData, setCarouselData] = useState([]);
  const [homeDescData, setHomeDescData] = useState([]);
  const [knowMoreData, setKnowMoreData] = useState(null);
  const [homeCommonData, setHomeCommonData] = useState({});
  const [serviceData, setServiceData] = useState([]);
  const [homeBlogData, setHomeBlogData] = useState(null);

  const notifyError = (error) =>{
    toast.dismiss();
    toast.error(error, {
      position: "top-center",
      autoClose: 3000,
    });
  }

  const animationProps = useSpring({
    transform: inView ? "translateY(0)" : "translateY(20px)",
    opacity: inView ? 1 : 0,
  });
  const carouselDataFunction = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}${CAROUSEL_END_POINT}`);
      if (response.status === 200) {
        setCarouselData(response.data?.data);
        setLoading(false);
      } else {
        setLoading(false);
        notifyError(response.message || "facing issues")
      }
    } catch (error) {
      setLoading(false);
      notifyError(error.message || "facing issues")
    } finally {
      setLoading(false);
    }
  }, []);

  const homeDescriptionFunction = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}${HOME_DESCRIPTION_END_POINT}`
      );
      if (response.status === 200) {
        setHomeDescData(response.data?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, []);

  const homeCommonDataunction = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}${HOME_SINGLE_API_END_POINT}`
      );
      if (response.status === 200) {
        setHomeCommonData(response.data?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, []);

  const homeKnowMoreFunction = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}${KNOW_MORE_END_POINT}`);
      if (response.status === 200) {
        setKnowMoreData(response.data?.data);

        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, []);

  const homeBlogDataFunction = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}${HOME_BLOG_LISTING_END_POINT}`
      );
      if (response.status === 200) {
        setHomeBlogData(response.data?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, []);

  const serviceDataFunction = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}${HOME_CARD_LISTING_END_POINT}`
      );
      if (response.status === 200) {
        setServiceData(response.data?.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    carouselDataFunction();
    homeDescriptionFunction();
    homeKnowMoreFunction();
    homeCommonDataunction();
    serviceDataFunction();
    homeBlogDataFunction();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div className="main-container">
      <NavbarComponent />
      <div style={{flex: 1}}>
      {carouselData && (
        <div className="main-carousel-section">
          <Carousel carouselData={carouselData} />
        </div>
      )}
      {/* hero section */}
      {homeDescData && (
        <div className="blog-home-main-container">
          <div className="hero-section-div">
            {homeDescData?.map((item) => {
              return <HeroSection key={item.id} data={item} />;
            })}
          </div>
        </div>
      )}
      {/* about componnet */}
      {knowMoreData && (
        <div className="blog-home-main-container">
          <AboutComponent aboutData={knowMoreData} />
        </div>
      )}
      {/* service component */}
      {homeCommonData?.why_choose_xcelyst && (
        <div className="blog-home-main-container1">
          <div className="blog-home-section">
            <animated.h1 className="sevice-header-text">
              Why Choose Xcelyst?
            </animated.h1>
            {homeCommonData?.why_choose_xcelyst && (
              <animated.p className="sevices-tag-line">
                {homeCommonData?.why_choose_xcelyst?.description}
              </animated.p>
            )}
            {serviceData && (
              <div className="services-card-div">
                {serviceData.map((item) => {
                  return <CardService data={item} />;
                })}

                <div className="ellipse25"></div>
                <div className="ellipse26"></div>
                <div className="ellipse27"></div>
                <div className="ellipse28"></div>
              </div>
            )}
          </div>
        </div>
      )}
      {/* vision section start */}
      {homeCommonData?.our_vision && (
        <VisionComponents visionData={homeCommonData?.our_vision} />
      )}
      {/* blog section start */}
      {homeBlogData && (
        <div className="blog-home-main-container">
          <div className="blog-home-section">
            <div className="blog-home-header-div">
              <animated.span className="blog-home-header">
                Latest From our Blog
              </animated.span>
              <animated.button
                className="home-contact-btn"
                onClick={() => navigate("/blogs")}
              >
                <span className="home-contact-btn-text">View All</span>
              </animated.button>
            </div>
              <div className="home-blog-list-div mt-40">
                {homeBlogData?.slice(0, 3)?.map((item) => {
                  return <BlogHome data={item} />;
                })}
              </div>
          </div>
        </div>
      )}
      {/* contact section start */}
      {homeCommonData?.contact_us && (
        <div className="contact-home-section mb-100">
          <ContactComponent contactInfo={homeCommonData?.contact_us} />
        </div>
      )}
      </div>
      <Footer />
      {loading && <Loader />}
    </div>
  );
};

export default HomePage;
