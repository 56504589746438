import {
  caraousel1,
  caraousel2,
  caraousel3,
  caraousel4,
  caraousel5,
  leader1,
  leader2,
  leader3,
  partner1,
  partner2,
  partner3,
  partner4,
  alarm,
  dollar,
  cloud,
  target,
  like,
  chartLine,
} from "./assets";

export const carouselData = [
  {
    id: 1,
    image: caraousel1,
    text: "Tapping into <span>Global knowledge pool</span> is now a <span>business Imperative</span>",
  },
  {
    id: 2,
    image: caraousel2,
    text: "<span>Transform</span> Your Hiring with Premier <span>Talent and Technology</span>",
  },
  {
    id: 3,
    image: caraousel3,
    text: "Access to <span>talent & technology is increasingly</span> a decisive <span>factor to win</span>",
  },
  {
    id: 3,
    image: caraousel4,
    text: "<span>Global capability centers (GCC)</span> enable this We can <span>help you get there</span>",
  },
  {
    id: 5,
    image: caraousel5,
    text: "<span>Critical Functions across</span> Industries are Being <span>Disrupted</span> by rapid advances in <span>Data, Analytics, AI & Technology</span>",
  },
];

export const aboutInfo = [
  {
    id: 1,
    title: "The New Normal",
    description:
      "Critical functions across Industries are being disrupted by rapid advances in Data, Analytics, AI & Technology. <br/><br /> Tapping into a global knowledge pool is now a business imperative. <br/><br /> Access to Talent & Technology at the right cost and appropriate scale is increasingly a decisive factor to win.",
  },
  {
    id: 2,
    title: "what we do",
    description:
      "We enable businesses to tap into the global knowledge pool by setting up and managing Global Capability Centers (GCC) in talent rich locations. <br /> <br /> Our comprehensive strategy is designed to guide you through every step of the process, with minimal risk, ensuring that your GCC becomes a powerhouse of innovation, efficiency, and competitive advantage in the global marketplace.",
  },
  {
    id: 3,
    title: "AT Xcelyst",
    description:
      "We provide end-to-end support from defining strategic objectives to execution.<br /> <br /> Our Principals have decades of CXO level experience setting up large global teams across industries and functions.<br /> <br />With our hands-on execution, you can be confident in your journey to establishing a world-class Global Capability Centre.",
  },
];

export const comprehensiveData = [
  {
    id: 1,
    name: "Educational & Technical Skills",
  },
  {
    id: 2,
    name: "Length of Service",
  },
  {
    id: 3,
    name: "Location",
  },
  {
    id: 4,
    name: "Soft skills",
  },
  {
    id: 5,
    name: "Leadership potential",
  },
];

export const teamExperienceData = [
  {
    id: 1,
    name: "CXO-level Global Experience",
    description:
      "Our seasoned Managing Principals bring decades of CXO-level global experience across diverse industries and functions. We have hands-on experience scaling large global teams/organizations hands-on both as entrepreneurs and CXOs.<br /> <br />Our wealth of knowledge ensures strategic insights and effective decision-making.",
  },
  {
    id: 2,
    name: "Operational Expertise",
    description:
      "Our Supporting Partners come with a comprehensive understanding of local finance, legal, tax, and HR nuances, providing comprehensive support tailored to your specific context.",
  },
  {
    id: 3,
    name: "Industry Experience",
    description:
      "Our Advisory board comes with deep industry experience and professional networks.",
  },
];

export const teamLeaderData = [
  {
    id: 1,
    name: "ANUNAY GUPTA, Phd",
    bio: "Anunay brings global experience with an entrepreneurial bent of mind. In his last role as Managing Director at JPMorgan Chase & Co, he was a member of the India Leadership Council & had responsibility for all India-based Credit and Fraud risk functions for the Retail Bank. Prior, he co-founded Marketelligent - an analytics consulting business that was subsequently acquired by Brillio.",
    linkdin: "https://www.linkedin.com/in/anunaygupta/",
    image: leader1,
  },
  {
    id: 2,
    name: "RITESH AGARWAL",
    bio: "Ritesh is a seasoned leader with 20+ years’ experience. Prior to Xcelyst, he was with CRISIL (a S&P Global Company) as UK Head, Global Head of Derivatives, and Sales Director. He was instrumental in setting up their Risk & Analytics practice of 600+ analysts across 6 countries.",
    linkdin: "https://www.linkedin.com/in/ritesh-agarwal-a619aa3a/",
    image: leader2,
  },
  {
    id: 3,
    name: "ASHUTOSH GUPTAL",
    bio: "Ashutosh has extensive experience building large global teams. He was co-CEO at Evalueserve, a Research and Analytics firm with over 4,000 employees, where he led the Company’s expansion in China, Chile, Romania, and the US. Prior to Evalueserve, Ashutosh worked as Executive Director at Goldman Sachs in New York and London.",
    linkdin: "https://www.linkedin.com/in/guptaashutosh1/",
    image: leader3,
  },
];

export const teamPartnerData = [
  {
    id: 1,
    name: "NAND GANGWANI",
    bio: "Nand has been CFO and COO of public and privately held companies for over 30 years and has led transformation of financial and operational processes across leading companies across industries. He also took Napster public on Nasdaq.",
    linkdin: "https://www.linkedin.com/in/nand-gangwani-1072bb5/",
    image: partner1,
  },
  {
    id: 2,
    name: "RADHAKRISHNAN S",
    bio: "Radha is a seasoned professional with experience in the Big 4 and a track record across multiple areas in taxation, finance, legal and Technology. He is also actively engaged in advisory capacity with Not-for-Profits & Startups. In a prior role, he served as CIO at Nestle South Asia.",
    linkdin: "https://www.linkedin.com/in/radhakrishnan-s-14507310/",
    image: partner2,
  },
  {
    id: 3,
    name: "NAMITA AKHAURI",
    bio: "Namita is a seasoned Human Resources professional with experience across industries. She has worked with Accenture Consulting, Morpheus Human Consulting, Recruitment SMART and Vodafone Idea Limited. At Accenture, she was instrumental in setting up the offshore 200+ Financial Services team.",
    linkdin: "https://www.linkedin.com/in/namita-akhauri-prasad-260b97a/",
    image: partner3,
  },
  {
    id: 4,
    name: "TARUN LALA",
    bio: "Tarun is a serial entrepreneur. While at EMC, he built his first venture: YesMastr – a delivery services consumer app. He is also the Founder of Levo Labz - a company that builds Software across the IT stack for startups and SMEs.",
    linkdin: "https://www.linkedin.com/in/tarunlala/",
    image: partner4,
  },
];

export const servicesData = [
  {
    id: 1,
    title: "Reduced Recruitment Time",
    desc: "Reduces the time required to locate and employ people by streamlining the hiring process with automation driven by AI.",
    icon: alarm,
  },
  {
    id: 2,
    title: "Cost Savings",
    desc: "Significantly Reduces Expenses of Hiring. Reduces hiring costs by using effective AI-driven procedures, which saves time and resources.",
    icon: dollar,
  },
  {
    id: 3,
    title: "Scalable and Flexible",
    desc: "Enables smooth adaption to your organization's evolving needs by providing scalable recruitment tools that are accessible from any location.",
    icon: cloud,
  },
  {
    id: 4,
    title: "Improved Matching Accuracy",
    desc: "Matches candidates' expertise and talents with job needs using cutting-edge ML algorithms, leading to more precise and fruitful placements.",
    icon: target,
  },
  {
    id: 5,
    title: "Enhanced Candidate Experience",
    desc: "Streamlines the application process by simplifying resume uploads and application tracking, ensuring a quick and user-friendly experience.",
    icon: like,
  },
  {
    id: 6,
    title: "Comprehensive Analytics",
    desc: "Helps recruiters make better hiring decisions with comprehensive analytics and data-driven insights on  candidate performance, and process efficiency.",
    icon: chartLine,
  },
];

export const BASE_URL = "https://xcelystbackend.4tysixapplabs.com/api/";
export const CANDIDATE_BASE_URL = "https://prod-admin.xcelyst.ai/";

//home page end points
export const CAROUSEL_END_POINT = "banner";
export const HOME_DESCRIPTION_END_POINT = "home_description";
export const KNOW_MORE_END_POINT = "home_know_more";
export const HOME_SINGLE_API_END_POINT = "home_single_description";
export const HOME_CARD_LISTING_END_POINT = "home_card_listing";
export const HOME_BLOG_LISTING_END_POINT = "blog_listing";
export const CONTACT_US_END_POINT = "contact_us";
export const ADDRESS_END_POINT = "our_branch_listing";

//about page end points
export const ABOUT_INFO_END_POINT = "about_us_data";

//team page end points
export const TEAM_INFO_END_POINT = "team_data";

export const PRIVACY_POLICY_END_POINT = "privacy_policy";
export const GDPR_END_POINT = "gdpr_policy";
export const TERM_END_POINT = "term_and_condition";

//like Page
export const LIKE_API_ENDPOINT = "like_blog";

//subscribe end points
export const SUBSCRIBE_API_END_POINT = "subscribe_user";
