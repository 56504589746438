import React from "react";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";

const VisionComponents = ({ visionData }) => {
  const [ref, inView] = useInView({ threshold: 0 });

  const titleAnimation = useSpring({
    transform: inView ? "translateY(0)" : "translateY(20px)",
    opacity: inView ? 1 : 0,
    delay: 100,
  });

  const descAnimation = useSpring({
    transform: inView ? "translateY(0)" : "translateY(20px)",
    opacity: inView ? 1 : 0,
    delay: 300,
  });

  return (
    <div ref={ref} className="vision-main-container">
      <animated.h1 style={titleAnimation} className="vision-header-text">
        Our Vision
      </animated.h1>
      <animated.p style={descAnimation} className="vision-disc-text">
        {visionData?.description}
      </animated.p>
    </div>
  );
};

export default VisionComponents;
