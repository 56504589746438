import * as React from "react";
import { usePagination } from "@material-ui/lab";
import { styled } from "@material-ui/styles";

//@ts-ignore
import PaginationStyle from "../../Styles.module.scss";
const List = styled("ul")({
  listStyle: "none",
  padding: 0,
  margin: 0,
  display: "flex",
  backgroundColor: "transparent",
});

export default function Pagination(props: any) {
  const { items } = usePagination({
    count: props.totalPages,
    defaultPage: props.defaultPages,
  });
  return (
    <nav className={PaginationStyle.pagination}>
      <List>
        {items.map(({ page, type, selected, ...item }, index) => {
          let children: any = null;
          if (props.totalPages === 1) {
            return "";
          }
          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = "…";
          } else if (
            type === "page" &&
            items[index ? index - 1 : 0].type !== "end-ellipsis"
          ) {
            let pageVal = page < 10 ? "0" + page : page;
            children = (
              <button
                type="button"
                style={{
                  fontWeight: 500,
                  color: selected ? "#093554" : "#09355499",
                  cursor: "pointer",
                  backgroundColor: "transparent",
                  fontSize: 12,
                }}
                {...item}
              >
                {pageVal}
              </button>
            );
          } else {
            if (props.totalPages > 1) {
              if (type == "next") {
                children = items?.length > 1 && (
                  <button
                    type="button"
                    style={{
                      fontWeight: 500,
                      color: !item.disabled ? "#093554" : "#09355499",
                      cursor: "pointer",
                      backgroundColor: "transparent",
                      fontSize: 12,
                    }}
                    {...item}
                  >
                    Next
                  </button>
                );
              }
              if (type == "previous") {
                children = items?.length > 1 && (
                  <button
                    type="button"
                    style={{
                      fontWeight: 500,
                      color: !item.disabled ? "#093554" : "#09355499",
                      cursor: "pointer",
                      backgroundColor: "transparent",
                      fontSize: 12,
                    }}
                    {...item}
                  >
                    Previous
                  </button>
                );
              }
            }
          }

          return (
            <li
              className={PaginationStyle.listItem}
              key={index}
              onClick={() =>
                type === "end-ellipsis" || type === "start-ellipsis"
                  ? ""
                  : props.onPageNumSelect(page)
              }
            >
              {children}
            </li>
          );
        })}
      </List>
    </nav>
  );
}
