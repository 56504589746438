import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import "./home.css";
import { cross } from "../../helper/assets";
import { Modal } from "react-bootstrap";

const AboutComponent = ({ aboutData }) => {
  const [ref, inView] = useInView({ threshold: 0 });
  const [aboutInfo, setAboutInfo] = useState({});
  const [modalVisible, setModalVisible] = useState(false);

  const titleAnimation = useSpring({
    transform: inView ? "translateY(0)" : "translateY(20px)",
    opacity: inView ? 1 : 0,
    delay: 100,
  });

  const descAnimation = useSpring({
    transform: inView ? "translateY(0)" : "translateY(20px)",
    opacity: inView ? 1 : 0,
    delay: 300,
  });

  const buttonAnimation = useSpring({
    transform: inView ? "translateY(0)" : "translateY(20px)",
    opacity: inView ? 1 : 0,
    delay: 500,
  });

  return (
    <div className="about-home-div">
      {true && <img src={aboutData[0]?.image} className="about-home-image" />}
      <div className="overlay-about-home" />
      {aboutData && (
        <div ref={ref} className="about-home-info-div">
          <animated.h1 style={titleAnimation} className="about-home-header">
            {aboutData[0]?.title}
          </animated.h1>
          <animated.p style={descAnimation} className="about-home-header-desc">
            {aboutData[0]?.description}
          </animated.p>
          <animated.button
            style={buttonAnimation}
            className="about-know-more-btn"
            onClick={() => {
              setAboutInfo(aboutData[0]);
              setModalVisible(true);
            }}
          >
            <span className="about-know-more-text">Know More</span>
          </animated.button>
        </div>
      )}
      <Modal
        show={modalVisible}
        onHide={() => setModalVisible(false)}
        dialogClassName="leader-Modal"
        contentClassName="about-data-modal"
        centered
      >
        <div className="about-info-modal">
          <div className="about-modal-first-div">
            <div />
            {aboutInfo?.title && (
              <h3 className="hero-header-tex ">{aboutInfo?.title}</h3>
            )}
            <img
              src={cross}
              className="crossContactImg"
              onClick={() => setModalVisible(false)}
            />
          </div>
          {aboutInfo?.description && (
            <p
              dangerouslySetInnerHTML={{
                __html: aboutInfo?.description,
              }}
              className="hero-desc-modal"
            />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default AboutComponent;
