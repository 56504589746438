import { Box, Button, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import DetailTabs from "./DetailsTabs.tsx";
//@ts-ignore
import DetailCandidateStyles from "../../Styles.module.scss";
import { CANDIDATE_BASE_URL } from "../../helper/config.js";

interface Detail {
  candidateData: any;
}

const PROD_URL = "https://prod-admin.xcelyst.ai";
const FRONTEND_URL = "https://www.xcelyst.ai";
class DetailCandidateParent extends React.Component<Detail> {
  constructor(props: Detail) {
    super(props);
  }

  render() {
    let currentPositionData, currentCompanyData, currentLocationData;
    let previousPositions: any = [];
    let otherJobs: any = [];
    let currentJobData: any = [];
    currentLocationData = this.props.candidateData?.location;
    let experinceData =
      this.props.candidateData?.temporary_user_profile?.work_experience ||
      this.props.candidateData?.position ||
      [];
    experinceData?.map((item: any) => {
      if (item?.current) {
        if (item?.position) {
          currentPositionData = item?.position;
          currentCompanyData = item?.company;
          let start = moment(item?.started?.split("T")?.[0], "YYYY/MM/DD");
          let startMonYear = start.format("MMM") + " " + start.format("YYYY");
          currentJobData = [
            { ...item, started: startMonYear, ended: "Current time" },
          ];
        } else {
          currentPositionData = "-";
        }
      } else {
        if (item?.position) {
          previousPositions.push(
            `${item?.position}${item.company ? " at " + item.company : ""}`
          );

          let start = moment(item?.started?.split("T")?.[0], "YYYY/MM/DD");
          let startMonYear = start.format("MMM") + " " + start.format("YYYY");
          let end = moment(item?.ended?.split("T")?.[0], "YYYY/MM/DD");
          let endMonYear = end.format("MMM") + " " + end.format("YYYY");
          let endjob = {
            ...item,
            started: item?.started ? startMonYear : null,
            ended: item?.ended ? endMonYear : null,
          };
          otherJobs.push(endjob);
        }
      }
    });
    let allJobs = [...currentJobData, ...otherJobs];

    return (
      <Box
        className={`${DetailCandidateStyles.card} ${DetailCandidateStyles.candidateDetailFromListWrap}`}
      >
        <Grid container className={DetailCandidateStyles.detailWrap}>
          <Grid item style={{ width: "100%" }}>
            <Box
              mb={2}
              display="flex"
              justifyContent="space-between"
              alignItems={"center"}
              flexWrap={"wrap"}
            >
              <p className="candidate-name">
                {this.props.candidateData?.full_name || "-"}
              </p>
              <a
                className="resume-btn"
                href={`${PROD_URL}/bx_block_database/download_pdf/${
                  this.props.candidateData?.temporary_user_profile
                    ?.temporary_user_database_id
                    ? this.props.candidateData?.temporary_user_profile
                        ?.temporary_user_database_id
                    : this.props.candidateData?.id
                }.pdf`}
                target="_blank"
                download
              >
                Download Resume
              </a>
            </Box>
            {currentPositionData && (
              <Box
                display="flex"
                className={DetailCandidateStyles.detailData}
                mb={2}
              >
                <Box pr={2}>
                  <Typography className={DetailCandidateStyles.detailTitle}>
                    Position:
                  </Typography>
                </Box>
                <Box paddingRight="1em">
                  <Typography
                    component="span"
                    className={DetailCandidateStyles.detailValue}
                  >
                    {currentPositionData}
                  </Typography>
                </Box>
              </Box>
            )}
            {currentCompanyData && (
              <Box
                display="flex"
                className={DetailCandidateStyles.detailData}
                mb={2}
              >
                <Box pr={2}>
                  <Typography className={DetailCandidateStyles.detailTitle}>
                    Company:
                  </Typography>
                </Box>
                <Box paddingRight="1em">
                  <Typography
                    component="span"
                    className={DetailCandidateStyles.detailValue}
                  >
                    {currentCompanyData}
                  </Typography>
                </Box>
              </Box>
            )}
            {this.props.candidateData?.experience?.trim() && (
              <Box
                display="flex"
                className={DetailCandidateStyles.detailData}
                mb={2}
              >
                <Box pr={2}>
                  <Typography className={DetailCandidateStyles.detailTitle}>
                    Experience:
                  </Typography>
                </Box>
                <Box paddingRight="1em">
                  <Typography
                    component="span"
                    className={DetailCandidateStyles.detailValue}
                  >
                    {this.props.candidateData?.experience} years
                  </Typography>
                </Box>
              </Box>
            )}
            {this.props.candidateData?.degree && (
              <Box
                display="flex"
                className={DetailCandidateStyles.detailData}
                mb={2}
              >
                <Box pr={2}>
                  <Typography className={DetailCandidateStyles.detailTitle}>
                    Degree:
                  </Typography>
                </Box>
                <Box paddingRight="1em">
                  <Typography
                    component="span"
                    className={DetailCandidateStyles.detailValue}
                  >
                    {this.props.candidateData?.degree}
                  </Typography>
                </Box>
              </Box>
            )}

            {this.props.candidateData?.linkedin_url && (
              <Box
                display="flex"
                className={DetailCandidateStyles.detailData}
                mb={2}
              >
                <Box pr={2}>
                  <Typography className={DetailCandidateStyles.detailTitle}>
                    Linkdin:
                  </Typography>
                </Box>
                <Box paddingRight="1em">
                  <a
                    href={`${this.props.candidateData?.linkedin_url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={DetailCandidateStyles.detailValue}
                  >
                    {this.props.candidateData?.linkedin_url}
                  </a>
                </Box>
              </Box>
            )}
            {this.props.candidateData?.score && (
              <Box
                display="flex"
                className={DetailCandidateStyles.detailData}
                mb={2}
              >
                <Box pr={2}>
                  <Typography className={DetailCandidateStyles.detailTitle}>
                    Xcelyst AI Score:
                  </Typography>
                </Box>
                <Box paddingRight="1em">
                  <Typography
                    component="span"
                    className={DetailCandidateStyles.detailValue}
                  >
                    {this.props.candidateData?.score}
                  </Typography>
                </Box>
              </Box>
            )}
            {currentLocationData && (
              <Box
                display="flex"
                className={DetailCandidateStyles.detailData}
                mb={2}
              >
                <Box pr={2}>
                  <Typography className={DetailCandidateStyles.detailTitle}>
                    location:
                  </Typography>
                </Box>
                <Box paddingRight="1em">
                  <Typography
                    component="span"
                    className={DetailCandidateStyles.detailValue}
                  >
                    {currentLocationData}
                  </Typography>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
        <Box className={DetailCandidateStyles.tabWrap}>
          {this.props.candidateData && (
            <DetailTabs
              candidateData={this.props.candidateData}
              allJobExperiences={allJobs}
            />
          )}
        </Box>
      </Box>
    );
  }
}

export default DetailCandidateParent;
