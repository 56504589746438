import React, { useEffect, useState } from "react";
import "../gdprpolicy-page/gdprPolicy.css";
import NavbarComponent from "../../components/navbar/NavbarComponent";
import Footer from "../../components/footer/Footer";
import { BASE_URL, PRIVACY_POLICY_END_POINT } from "../../helper/config";
import axios from "axios";
import Loader from "../../components/loader/Loader";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const PrivacyPolicy = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [privacyData, setPrivacyData] = useState([]);

  const notifyError = (error) =>{
    toast.dismiss();
    toast.error(error, {
      position: "top-center",
      autoClose: 3000,
    });
  }

  const termFunction = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}${PRIVACY_POLICY_END_POINT}`
      );
      if (response.status === 200) {
        setPrivacyData(response.data?.data);
        setLoading(false);
      } else {
        setLoading(false);
        notifyError(response.message || "facing issues")
      }
    } catch (error) {
      setLoading(false);
      notifyError(error.message || "facing issues")
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    termFunction();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <div className="main-container">
      <NavbarComponent />
      <div style={{ flex: 1 }}>
        <div className="gdprMain">
          <p className="gdprPolicyHeader">PRIVACY POLICY</p>
          {privacyData && (
            <p
              className="gdprPolicyContentTitle"
              dangerouslySetInnerHTML={{ __html: privacyData[0]?.text }}
            />
          )}
        </div>
      </div>
      <Footer />
      {loading && <Loader />}
    </div>
  );
};

export default PrivacyPolicy;
