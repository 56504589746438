import React, { useEffect, useCallback, useState } from "react";
import "./about.css";
import NavbarComponent from "../../components/navbar/NavbarComponent";
import Footer from "../../components/footer/Footer";
import { about1, about2 } from "../../helper/assets";
import { BASE_URL, ABOUT_INFO_END_POINT } from "../../helper/config";
import axios from "axios";
import Loader from "../../components/loader/Loader";
import { useSpring, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const About = () => {
  const location = useLocation();
  const [aboutRef1, inView1] = useInView({ threshold: 0 });
  const [aboutRef2, inView2] = useInView({ threshold: 0 });
  const [aboutRef3, inView3] = useInView({ threshold: 0 });
  const [aboutRef4, inView4] = useInView({ threshold: 0 });
  const [aboutRef5, inView5] = useInView({ threshold: 0 });
  const [aboutRef6, inView6] = useInView({ threshold: 0 });

  const animationProps1 = useSpring({
    transform: inView1 ? "translateY(0)" : "translateY(20px)",
    opacity: inView1 ? 1 : 0,
  });

  const animationProps2 = useSpring({
    transform: inView2 ? "translateY(0)" : "translateY(20px)",
    opacity: inView2 ? 1 : 0,
  });

  const animationProps3 = useSpring({
    transform: inView3 ? "translateY(0)" : "translateY(20px)",
    opacity: inView3 ? 1 : 0,
  });

  const animationProps4 = useSpring({
    transform: inView4 ? "translateY(0)" : "translateY(20px)",
    opacity: inView4 ? 1 : 0,
  });

  const animationProps5 = useSpring({
    transform: inView5 ? "translateY(0)" : "translateY(20px)",
    opacity: inView5 ? 1 : 0,
  });

  const animationProps6 = useSpring({
    transform: inView6 ? "translateY(0)" : "translateY(20px)",
    opacity: inView6 ? 1 : 0,
  });

  const notifyError = (error) => {
    toast.dismiss();
    toast.error(error, {
      position: "top-center",
      autoClose: 3000,
    });
  };

  const [aboutData, setAboutData] = useState({});
  const [loading, setLoading] = useState(false);

  const aboutDataFunction = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}${ABOUT_INFO_END_POINT}`);
      if (response.status === 200) {
        setAboutData(response.data?.data);
        setLoading(false);
      } else {
        setLoading(false);
        notifyError(response.message || "facing issues");
      }
    } catch (error) {
      setLoading(false);
      notifyError(error.message || "facing issues");
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    aboutDataFunction();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const renderSection = (position) => {
    switch (position) {
      case "about_us_1_position":
        return (
          aboutData?.about_us_1_section && (
            <div>
              {aboutData?.about_us_1_section?.image && (
                <animated.div
                  ref={aboutRef2}
                  style={animationProps2}
                  className="about-section-2"
                >
                  <img
                    src={aboutData?.about_us_1_section?.image}
                    className="about-image-1"
                  />
                  <div className="overlay-about-1" />
                </animated.div>
              )}
              {aboutData?.about_us_1_section?.description_2 && (
                <animated.div
                  ref={aboutRef3}
                  style={animationProps3}
                  className="about-section-3"
                >
                  {aboutData?.about_us_1_section?.description_2}.
                </animated.div>
              )}
            </div>
          )
        );
      case "about_us_2_position":
        return (
          aboutData?.about_us_2_section && (
            <div>
              <animated.div
                ref={aboutRef4}
                style={animationProps4}
                className="about-section-4"
              >
                <div className="inner-div-section-4">
                  <h1 className="about-section-4-header">Why Pick Xcelyst?</h1>
                  <div className="blogBorder mv-24" />
                  {aboutData?.about_us_2_section?.description && (
                    <div className="about-section-4-desc">
                      {aboutData?.about_us_2_section?.description}
                    </div>
                  )}
                </div>
              </animated.div>

              <div className="about-section-2 about-5-shadow ">
                {aboutData?.about_us_2_section?.image && (
                  <img
                    src={aboutData?.about_us_2_section?.image}
                    className="about-image-1"
                  />
                )}
                <div className="overlay-about-2" />
                <animated.div
                  ref={aboutRef5}
                  style={animationProps5}
                  className="about-5-div"
                >
                  <div>
                    {aboutData?.about_us_2_section?.image_title_1 && (
                      <h2 className="about-5-header-text">
                        {aboutData?.about_us_2_section?.image_title_1}
                      </h2>
                    )}
                    {aboutData?.about_us_2_section?.image_description_1 && (
                      <p className="about-5-desc-text">
                        {aboutData?.about_us_2_section?.image_description_1}
                      </p>
                    )}
                  </div>
                  <div>
                    {aboutData?.about_us_2_section?.image_title_2 && (
                      <h2 className="about-5-header-text">
                        {aboutData?.about_us_2_section?.image_title_2}
                      </h2>
                    )}
                    {aboutData?.about_us_2_section?.image_description_2 && (
                      <p className="about-5-desc-text">
                        {aboutData?.about_us_2_section?.image_description_2}
                      </p>
                    )}
                  </div>
                </animated.div>
              </div>
            </div>
          )
        );
      case "about_us_3_position":
        return (
          <animated.div
            ref={aboutRef6}
            style={animationProps6}
            className="about-section-6"
          >
            <h1 className="about-section-6-header">
              Comprehensive & Fair Recruitment with Xcelyst
            </h1>
            <p className="about-section-6-desc">
              Our platform goes beyond standard attributes, incorporating
              factors such as
            </p>
            <div className="skills-div">
              {aboutData?.about_us_3_section?.map((item) => {
                return (
                  <div className="skill-list-div" key={item.id}>
                    {item?.title}
                  </div>
                );
              })}
            </div>
            <div className="ellipse25"></div>
            <div className="ellipse26"></div>
            <div className="ellipse27"></div>
            <div className="ellipse28"></div>
          </animated.div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="main-container">
      <NavbarComponent />
      <div style={{ flex: 1 }}>
        {" "}
        <animated.div
          ref={aboutRef1}
          style={animationProps1}
          className="about-section-1"
        >
          <h1 className="about-text">About Us & Our Mission</h1>
          <div className="blogBorder mv-24" />
          <div className="about-info-div">
            <text className="about-info-text-1">
              {aboutData?.about_us_1_section?.header}
            </text>
            <text className="about-info-text-2">
              {aboutData?.about_us_1_section?.description_1}
            </text>
          </div>
        </animated.div>
        {Object.values(aboutData?.about_us_position || {}).map((position) =>
          renderSection(position)
        )}
      </div>
      <Footer />
      {loading && <Loader />}
    </div>
  );
};

export default About;
